import React, {Component} from "react";
import withStyles from '@material-ui/core/styles/withStyles';
const styles = theme => ({
    root: {
        fontFamily: "'Roboto', sans-serif",
        marginTop: 0,
        fontSize: "1.313rem",
        color: theme.palette.common.white,
        //height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "right",
        fontWeight: 400,
        alignSelf: "flex-end",
        '& span': {
            fontWeight: 500
        }
    },
    trademark: {
        fontSize: "0.66rem",
        fontWeight: 500,
        alignSelf: "flex-start"
    }
});
class MyGlobeIcon extends Component {
    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                my<span>globe</span>
                <span className={classes.trademark}>TM</span>
            </div>
        );
    }
}
export default withStyles(styles)(MyGlobeIcon);