//import update from "react-addons-update";
import * as DataActions from '../actions/DataActions';

export default function reducer(
    state = {
        loading: false,
        uploading: false,
        data: [],
        stories: [],
        panels: []
    }, action) {
        switch (action.type) {
            /* ======================== */
            // ACTIVITIES
            /* ======================== */
            case DataActions.FETCH_ACTIVITIES: { return {...state, loading: true} }
            case DataActions.FETCHED_ACTIVITIES: { return {...state, data: action.payload, loading: false} }
            /* ======================== */
            // PANELS
            /* ======================== */
            case DataActions.FETCH_PANELS: { return {...state, loading: true} }
            case DataActions.FETCHED_PANELS: { return {...state, panels: action.payload, loading: false} }
            /* ======================== */
            // DASHBOARD
            /* ======================== */
            case DataActions.UPLOAD_FILE: { return {...state, uploading: true} }
            case DataActions.UPLOAD_COMPLETE: { return {...state, uploading: false} }
        }
    return state;
}