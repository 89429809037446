/* ================================================================ */
// MENU COMPONENT | Menu.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
// Material UI
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    tabs: {
        fontSize: "0.9rem"
    }
});
/* ========================================== */
/**
 * Main Menu
 * ---------
 * Outputs a main navigation menu according to
 * supplied menu items array
 * 
 * @param {object} classes style classes
 * @param {array} menu menu items array
 * @param {string} page current page value
 */
/* ========================================== */
class Menu extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        menu: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        })),
        page: PropTypes.string
    }
    static defaultProps = {
        menu: [],
        page: 'home'
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        const tabs = this.props.menu.map(menuItem=>{
            return (
                <Tab
                    key={`menuItem_${menuItem.path}`} 
                    value={menuItem.path} 
                    label={menuItem.label}
                    component={Link} 
                    to={`/${menuItem.path}`}
                /> 
            );
        });
        return (
            <AppBar position="relative" color="secondary" elevation={10}>
                <Tabs 
                    className={classes.tabs}
                    value={this.props.page}
                    indicatorColor="primary"
                    fullWidth
                    scrollable={false}
                >
                    {tabs}
                </Tabs>
            </AppBar>
        );
    }
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Menu);