/* ================================================================ */
// LAYOUT CONTAINER | Layout.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Router, Route, Switch, Redirect} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
// MaterialUI
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';
// MUI Datepicker
//import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
//import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// Local Components
import Map from '../containers/Map';
import Sidebar from '../containers/Sidebar';
//import Dashboard from '../containers/Dashboard';
// Assets
import muiTheme from '../styles/theme';

import {mapConfig} from '../assets/mapConfig';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row"
    },
    map: {
        width: `calc(100% - ${muiTheme.components.sidebar.width})`,
        height: "100vh"
    },
    sidebar: {
        //width: muiTheme.components.sidebar.width,
        height: "100vh"
    }
});
const history = createBrowserHistory();
const location = history.location;
/* ========================================== */
/**
 * Layout
 * ------
 * App layout
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 */
/* ========================================== */
class Layout extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        screen: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }),
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // React print force update on print media
        window.matchMedia('print').addListener(this.updateComponent);
    }

    componentWillUnmount() {
        // React print force update on print media
        window.matchMedia('print').removeListener(this.updateComponent);
    }
    /* ======================== */
    // HANDLERS
    /* ======================== */
    /* ========================================== */
    /**
     * Update Component Event Handler
     * ------------------------------
     * Forces updating on layout component
     * (used for print media changes)
     * 
     * @param {Event} event
     */
    /* ========================================== */
    updateComponent = (event) => {
        console.log('updateComponent', event.matches);
        ReactDOM.flushSync(() => {
            this.forceUpdate();
        });
    };
    /* ========================================== */
    /**
     * Toggle Full Screen
     * ------------------
     * Changes window to full screen mode
     */
    /* ========================================== */ 
    toggleFullScreen() {
        var doc = window.document;
        var docEl = doc.documentElement;
        
        var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
        
        if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl);
            this.resize();
        }
        else {
            cancelFullScreen.call(doc);
        }
    }    

    /* ======================== */
    // RENDER
    /* ======================== */
    // Main Render
    render() {
        const classes = this.props.classes;
        const rootClasses = classNames(classes.root);
        const Main = (routeProps) => {
            ga('send', {
                hitType: 'event',
                eventCategory: 'mg_path',
                eventAction: 'path_change',
                eventLabel: 'page',
                eventValue: `${routeProps.location.pathname}`
            });
            return (
                <main className={rootClasses}>
                    <Map 
                        classes={{root: classes.map}} 
                        screen={this.props.screen} 
                        mapboxApiAccessToken={mapConfig.MAPBOX.MAPBOX_TOKEN} 
                        baseMapStyle={mapConfig.MAPBOX.MAP_STYLE} 
                        coords={mapConfig.MAPBOX.INITIAL_COORDS} 
                    />
                    <Sidebar page={routeProps.match.params.page} classes={{root: classes.sidebar}}/>
                </main>
            );
        }
        return (
            <Router history={history}>
                <MuiThemeProvider theme={muiTheme}>
                    {//<MuiPickersUtilsProvider utils={MomentUtils}>
                    }
                        <Switch>
                            {/*<Route path="/admin" component={Dashboard}/>*/}
                            <Redirect exact from="/" to="/timeline"/>
                            <Route path="/:page" render={routeProps=>{ return  <Main {...routeProps}/> }} />
                        </Switch>
                    {//</MuiPickersUtilsProvider>
                    }
                </MuiThemeProvider>
            </Router>
        );
    }
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Layout);