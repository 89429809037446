import axios from 'axios';

export const UPDATE_MAPVIEW = 'UPDATE_MAPVIEW';
export const UPDATE_STYLES = 'UPDATE_STYLES';
export const DATA_ERROR = 'DATA_ERROR';

export function updateMapView(mapView) {
    return (dispatch) => {
        dispatch({type: UPDATE_MAPVIEW, payload: mapView});
    }
}

export function updateMapStyle(styles) {
    return (dispatch, getState) => {
        var state = getState();
        axios.get(`https://api.mapbox.com/styles/v1/${state.MapReducer.mapboxUserID}/${styles[0]}?fresh=true&access_token=${state.MapReducer.accessToken}`).then( (result) => {
            console.log(result);
            axios.get(`https://api.mapbox.com/styles/v1/${state.MapReducer.mapboxUserID}/${styles[0]}/sprite?fresh=true&access_token=${state.MapReducer.accessToken}`).then((spriteJSON) => {
                dispatch({type: UPDATE_STYLES, payload: { style: result.data, spriteJSON: spriteJSON.data, spriteImage: `https://api.mapbox.com/styles/v1/${state.MapReducer.mapboxUserID}/${styles[0]}/sprite.png?fresh=true&access_token=${state.MapReducer.accessToken}` }});
            }).catch((error) => {
                dispatch({ type: DATA_ERROR, payload: "spriteJSONError" })
            });            
        }).catch((error) => {
            dispatch({ type: DATA_ERROR, payload: "styleError" })
        });
    }
}