
import {FirebaseDB, FirebaseStorage} from '../Firebase';

export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCHED_ACTIVITIES = 'FETCHED_ACTIVITIES';

export const FETCH_PANELS = 'FETCH_PANELS';
export const FETCHED_PANELS = 'FETCHED_PANELS';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';

// Media Types
export const MEDIA = {
    VIDEO: 'video',
    IMAGE: 'image'
}

export function fetchActivities() {
    return(dispatch, getState) => {
        dispatch({type: FETCH_ACTIVITIES});
        FirebaseDB.getCollection('activities', {field:'timestamp', order:'desc'})
            .then((snapshot)=>{
                var dataPromises = [];
                snapshot.forEach((doc)=>{
                    dataPromises.push(new Promise((resolve, reject)=>{
                        const data = doc.data();
                        // Get Media Requests (if media prop exists)
                        //const mediaPromises = (data.media) ? data.media.map((item)=>{return FirebaseStorage.downloadFileFromGSURL(item.url)}) : [];
                        // Process Media Requests
                        /*if(mediaPromises.length > 0) {
                            Promise.all(mediaPromises)
                            .then(urls=>{
                                const media = data.media.map((item,index)=>{return {type: "image", url: urls[index]}});
                                console.log(media);
                                // Return data for document including array of media download urls
                                resolve({id: doc.id, data: data, media: media});
                            })
                            .catch(error=>reject(error));
                        } else {
                            resolve({id: doc.id, data: data, media: []});
                        }*/
                        const media = data.media || [];
                        resolve({id: doc.id, data: data, media: media});
                    }));
                });
                // Process all data requests before returning response
                Promise.all(dataPromises)
                .then(res=>{
                    dispatch({type: FETCHED_ACTIVITIES, payload: res});
                })
                .catch(error=>console.log(error));
            })
            .catch((error)=>console.log(error));
    }
}
/**
 * Fetch Panels
 * ------------
 * Retrieves panels from a given
 * story subcollection and saves to state
 * 
 * @param {string} story story id
 */
export function fetchPanels(story) {
    return(dispatch) => {
        dispatch({type: FETCH_PANELS});
        FirebaseDB.getSubCollection('stories', story, 'panels', {field:'order', order:'asc'})
            .then((snapshot)=>{
                var dataPromises = [];
                snapshot.forEach((doc)=>{
                    dataPromises.push(new Promise((resolve, reject)=>{
                        const data = doc.data();
                        // Get Media Requests (if media prop exists)
                        const mediaPromises = (data.media) ? data.media.map((item)=>{return FirebaseStorage.downloadFileFromGSURL(item)}) : [];
                        // Process Media Requests
                        Promise.all(mediaPromises)
                        .then(urls=>{
                            console.log(urls)
                            // Return data for document including array of media download urls
                            resolve({id: doc.id, data: data, media: urls});
                        })
                        .catch(error=>reject(error));
                    }));
                });
                // Process all data requests before returning response
                Promise.all(dataPromises)
                .then(res=>{
                    dispatch({type: FETCHED_PANELS, payload: res});
                })
                .catch(error=>console.log(error));
            })
            .catch((error)=>console.log(error));
    }
}
/*
export function addActivity(data) {
    if(data 
    && data.title 
    && data.date
    && data.type) {
        // Announce modification of activities
        dispatch({type: FETCH_ACTIVITIES});
        if(data.media) {
            data.media.map((file)=>{
                const path = `media/activities/${file.name}`
                FirebaseStorage.uploadFile(path, file.metadata, file)
                .on('state_changed', (snapshot)=>{
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(progress);
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                    }
                }, (error)=>{
                    // Error in Upload
                    console.log(error);
                }, () => {
                    // Completed Upload
                    
                })
            })
        }
        FirebaseDB.addData('activities', data);
    } else {

    }
}*/