//import update from "react-addons-update";
import * as GlobeActions from '../actions/GlobeActions';
export default function reducer(
    state = {
        screen: {
            width: window.innerWidth, 
            height: window.innerHeight
        },
        loading: false,
        pages: [],
        menu: []
    }, action) {
        switch (action.type) {
            /* ======================== */
            // SCREEN
            /* ======================== */
            case GlobeActions.UPDATE_SCREEN: { return {...state, screen: action.payload} }
            /* ======================== */
            // PAGES
            /* ======================== */
            case GlobeActions.FETCH_PAGES: { return {...state, loading: false} }
            case GlobeActions.FETCHED_PAGES: { return {...state, pages: action.payload, loading: false} }
            /* ======================== */
            // MAIN MENU
            /* ======================== */
            case GlobeActions.FETCH_MENU: { return {...state, loading: true} }
            case GlobeActions.FETCHED_MENU: { return {...state, menu: action.payload, loading: false} }
        }
    return state;
}