/* ================================================================ */
// HEADER COMPONENT | Header.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
// Local components
import MyGlobeIcon from '../../components/Branding/MyGlobeIcon';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    header: {
        display: "flex",
        width: "100%",
        height: "5rem",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.background.dark
    }
});
/* ========================================== */
/**
 * Header
 * ------
 * Header output with provided branding
 * 
 * @param {object} classes style classes
 * @param {Node} branding app branding
 */
/* ========================================== */
class Header extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        branding: PropTypes.node
    }
    static defaultProps = {
        branding: <MyGlobeIcon />
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page
        }
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        return (
            <header className={classes.header}>
                {this.props.branding}
            </header>
        )
    }
}

/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Header);