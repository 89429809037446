/* ================================================================ */
// FIREBASE CONFIGURATION | Firebase.js
/* ================================================================ */
import firebase from 'firebase/app';
//import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
/* ========================================== */
// Initialisation
/* ========================================== */
/* ======================== */
// CONFIG
/* ======================== */
const config = {
    apiKey: "AIzaSyAtyvEvG14v_TYHxWXrLa7l4ssBpq9Eua8",
    authDomain: "myglobe-woodlea.firebaseapp.com",
    databaseURL: "https://myglobe-woodlea.firebaseio.com",
    projectId: "myglobe-woodlea",
    storageBucket: "myglobe-woodlea.appspot.com",
    messagingSenderId: "13764420217"
};
/* ======================== */
// INIT
/* ======================== */
firebase.initializeApp(config);
/* ========================================== */
// Firebase Components
/* ========================================== */
//const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();
/* ========================================== */
// Auth Providers
/* ========================================== */
/*const providers = {
    GOOGLE: new firebase.auth.GoogleAuthProvider(),
    FACEBOOK: new firebase.auth.FacebookAuthProvider(),
    TWITTER: new firebase.auth.TwitterAuthProvider(),
    GITHUB: new firebase.auth.GithubAuthProvider()
}*/
/* ========================================== */
// Export Components for Local Functions
/* ========================================== */
export {
    database,
    storage,
    //auth,
    //providers
};
