/* ================================================================ */
// ACTIVITY PAGE COMPONENT | ActivityPage.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect, dispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import animateScrollTo from 'animated-scroll-to';
// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import theme, { remToPx } from "../styles/theme";
// Actions
import { fetchActivities } from "../actions/DataActions";
import { updateMapView, updateMapStyle } from "../actions/MapActions";
// Local Components
import Filters from '../components/Filters'
import Item from '../components/Item';
import ActivityPage from '../components/HTMLPage/Pages/ActivityPage';
import TimelineGraph from '../components/TimelineGraph';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {},
    loadingContainer: {},
    loading: {},
    graphContainer: {
        height: "15rem",
        backgroundColor: theme.palette.background.dark
    },
    /*filterContainer: {
        position: "relative",
        height: "2.5rem",
        backgroundColor: theme.palette.background.default,
        '&.expanded': {
            height: "15rem"
        }
    },*/
    activities: {
        position: "relative",
        maxHeight: "calc(100% - 15em)",
        overflowY: "auto",        
    }
});
/* ========================================== */
/**
 * Activities
 * ------------
 * Activities page component
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 * @param {string} type variant page type (activity, feed, default)
 */
/* ========================================== */
class Activities extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        this.activitiesRef = React.createRef();
        this.state = {
            graphData:[],
            colors: window.categories,
            keys: Object.keys(window.categories),
            scrollX:1,
            scrollW:1,
            surveyDates:[],
            autoScrolling:false,
        }
    }
    componentDidMount() {
        this.props.fetchActivities();        
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.data!=this.props.data){
            //group the data via month for timelinegraph     
            let graphData = nextProps.data.reduce((acc,cur,idx,src)=>{
                //console.log(cur.data.timestamp.toDate());
                //console.log("WEEK OF THE YEAR",moment(cur.data.timestamp.toDate()).isoWeek());
                var yymm = moment(cur.data.timestamp.toDate()).format('YY-MM-W');
                var objByDate = {};
                var filtered = acc.filter(x=>x.date==yymm);
                if(filtered.length==0){
                    objByDate = {date:yymm};
                    this.state.keys.forEach(x=>objByDate[x]=0);
                    acc.push(objByDate);
                } else 
                    objByDate = filtered[0];
                objByDate[cur.data.category]++; 
                if(cur.data.category==="Action") { 
                    if(!objByDate.ActionData)
                        objByDate.ActionData=[cur.data.title]
                    else
                        objByDate.ActionData.push(cur.data.title)             
                }
                return acc;
            },[]);
            // add decisions for testing
            /*graphData.find(x=>x.date=='18-07-31').Action+=1;
            graphData.find(x=>x.date=='18-07-31').ActionData=["Action 1"];
            graphData.find(x=>x.date=='18-09-35').Action+=1;
            graphData.find(x=>x.date=='18-09-35').ActionData=["Action 2"];*/
            graphData.reverse();
            const surveyDates = [{ind:graphData.findIndex(x=>x.date==moment("2018-05-09").format('YY-MM-W')),title:"Survey"}];
            this.setState({graphData, surveyDates},()=>{this.calcScrollerPos()});
        }
    }
    // shouldComponentUpdate(nextProps,nextState) {
    //     if(nextState.scrollW!=this.state.scrollW || nextState.scrollX !=this.state.scrollX)
    //         return false;
    //     return true;
    // }
    componentDidUpdate() {
        /*if(this.activitiesRef.current)
           this.setState({scrollX:100,scrollW:1});*/
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    /* ========================================== */
    /**
     * Handle Click
     * ------------
     * Trigger click interaction actions
     * for given activity
     * 
     * @param {number} index activity index 
     */
    /* ========================================== */
    handleClick(index) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'mg_content_interact',
            eventAction: 'item_click',
            eventLabel: `${this.props.data[index].timestamp}-${this.props.data[index].title}`,
            eventValue: `${this.props.data[index].timestamp}-${this.props.data[index].title}`
        });
        const activity = this.props.data[index];
        if (activity.data.interactions && activity.data.interactions.onClick.length > 0) {
            activity.data.interactions.onClick.map( (action) => {
                if (action.type == "flyTo") {
                    this.props.updateMapView(action.mapView);
                }
                else if (action.type == "showDatasets") {
                    this.props.updateMapStyle(action.ids);
                }
            })                
        }        
    }
    /* ========================================== */
    /**
     * Handle Scroll
     * ------------
     * Listens to window scroll and gets the active activities
     * 
     * @param {number} index activity index 
     */
    /* ========================================== */
    handleScroll =(evt)=> {
        if(!this.state.autoScrolling)
            this.calcScrollerPos();
    }
    // calc scroller pos
    calcScrollerPos = ()=>{
        const activities = this.activitiesRef.current;
        const bounds = activities.getBoundingClientRect();
        let childRect;
        const offset = activities.scrollTop;
        let visible = [];
        // check what dates are visible
        Array.from(activities.children).forEach(el=>{
            childRect = el.getBoundingClientRect();
            // use top only so we just check if the top date bit in view?
            if(((childRect.top>=bounds.top && childRect.top<=bounds.bottom) || (childRect.top<bounds.top && childRect.bottom>bounds.bottom)) && el.dataset.date!=undefined){
                visible.push(el);
            }            
        });
        // filter out dupes
        const dates = [...(new Set(visible.map(x=>x.dataset.date)))];
        // calc the left and w in % for scoller box
        const scrollX = this.state.graphData.map(x=>x.date).indexOf(dates[(dates.length-1)])/this.state.graphData.length*100;
        //const scrollW = dates.length/this.state.graphData.length*100;//(this.state.graphData.map(x=>x.date).indexOf(dates[dates.length-1])-scrollXInd+1)/this.state.graphData.length*100;
        const scrollW = bounds.width/this.state.graphData.length;        
        // update state if diff
        if((this.state.scrollX!=scrollX || this.state.scrollW!=scrollW)) {
            this.setState({scrollX,scrollW});
        }
    }
    // move scroller to date
    scrollToDate = (date)=>{
        console.log('scroll to date:',date);
        const {graphData} = this.state;
        const activities = this.activitiesRef.current;
        const bounds = activities.getBoundingClientRect();        
        const ind = graphData.findIndex(x=>x.date==date);
        const elBounds = Array.from(activities.children).find(x=>x.dataset.date===date).getBoundingClientRect()
        const scrollX = ind/graphData.length*100;
        this.setState({autoScrolling:true,scrollX},()=>{
            animateScrollTo(activities.scrollTop + elBounds.top - bounds.top,{element:activities,onComplete:()=>{ this.setState({autoScrolling:false});}})
        });
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    renderActivities() {
        const output = this.props.data.map((itemData, index)=>{
            if(itemData.data.category!=="Action")
                return (
                    <Item
                        key={`activity_${index}`}
                        data={itemData} 
                        index={index}
                        hasClick={itemData.data.interactions && itemData.data.interactions.onClick.length > 0}
                        handleClick={(index)=>this.handleClick(index)} 
                    />
                )
        });        
        return output;
    }
    render() {
        const classes = this.props.classes;
        const calcWidth = this.props.screen.width * 0.4;
        const fixedWidth = remToPx(theme.components.sidebar.minWidth);
        const width = (calcWidth > fixedWidth) ? calcWidth : fixedWidth;
        if(this.props.loading) {
            return (<div className={classes.loadingContainer}><CircularProgress size={200} /></div>)
        } else {
            return (
                <div className={classes.root}>
                    <div className={classes.graphContainer}>
                        <TimelineGraph width={width} height={225} 
                            data={this.state.graphData} colors={this.state.colors} keys={this.state.keys} 
                            scrollX={this.state.scrollX} scrollW={this.state.scrollW} 
                            scrollToDate={this.scrollToDate}
                            surveyDates={this.state.surveyDates}
                            //onClick={()=>this.handleClick}
                        />
                    </div>
                    <div className={classes.activities} onScroll={this.handleScroll} ref={this.activitiesRef}>
                        {this.renderActivities()}
                    </div>
                </div>
            );
        }
    }
}
/* ========================================== */
// Redux
/* ========================================== */
const mapStateToProps = store =>{
    return { 
        loading: store.DataReducer.loading, 
        data: store.DataReducer.data,
        screen: store.GlobeReducer.screen
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({fetchActivities, updateMapView, updateMapStyle, dispatch}), dispatch)
}
/* ========================================== */
// Export
/* ========================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Activities));

