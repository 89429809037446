/* ================================================================ */
// ITEM COMPONENT | Item.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swiper from 'react-id-swiper';
import axios from 'axios';
// Material UI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import MarkerIcon from '@material-ui/icons/RadioButtonChecked';
import moment from 'moment';
// Action Types
import {MEDIA} from '../../actions/DataActions';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: "1em"
    },
    dateContainer: {
        minWidth: "3em",
        width: "5.5em",
        marginLeft: "1em",
        marginRight: "0.5em",
        textTransform: "uppercase"
    },
    markerContainer: {
        position: "relative",
        zIndex: 1,
        width: "2.5em",
        marginLeft: "0.5em",
        marginRight: "0.5em"
    },
    marker: {
        width: '2.5em',
        height: '2.5em'
    },
    timeline: {
        zIndex: -1,
        position: "absolute",
        left: "1.1em",
        width: "0.2em",
        height: "110%",
        backgroundColor: theme.palette.primary.main
    },
    loadingContainer: {},
    loading: {},
    media: {
        height: "20em"
    },
    mediaSwiper: {
        width: "100%",
        height: "20em",
        overflow: "hidden"
    },
    mediaSwiperWrapper: {
        display: "flex",
        justifyItems: "flex-start",
        height: "100%"
    },
    mediaSwiperPagination: {
        width: "25em",
        position: "relative"
    },
    mediaSwiperPaginationBullet: {
        backgroundColor: theme.palette.primary.dark,
        '&.active': {
            backgroundColor: theme.palette.primary.main
        }
    },
    activityContainer: {
        width: "calc(100% - 10em)",
        marginLeft: "0.5em",
        marginRight: "1em"
    },
    activity: {
        border: `0.05rem solid ${theme.palette.background.contentFrame}`,
        minWidth: "20em",
        marginBottom: "0.5em",
    },
    header: {
        cursor: 'default',
        '& span': {
            cursor: 'default',
        }
    },
    clickable: {
        
        '& span': {
            //color: theme.palette.text.secondary,
            fontWeight: 'bold',
            cursor: 'pointer'
        }
    }
});
/* ========================================== */
// Constants
/* ========================================== */
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]
/* ========================================== */
/**
 * Item
 * ----
 * Outputs a feed item
 * 
 * @param {object} classes style classes
 * @param {object} data item's data
 * @param {number} index item index
 */
/* ========================================== */
class Item extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        data: PropTypes.shape({
            data: PropTypes.shape({
                title: PropTypes.string.isRequired,
                timestamp: PropTypes.object.isRequired,
                category: PropTypes.string.isRequired
            }).isRequired,
            media: PropTypes.arrayOf(PropTypes.object)
        }).isRequired,
        index: PropTypes.number.isRequired,
        hasClick: PropTypes.bool.isRequired
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        this.state={
            showMedia: false
        }
    }
    componentDidMount() {
        //FB.xfbml.parse();
    }
    componentDidUpdate() {
        //FB.xfbml.parse();
    }
    handleMediaError = (error) => {
        console.log(error);
        this.setState({showMedia: false});
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    // Output media
    renderMedia(dateOutput) {
        const classes = this.props.classes;
        const data = this.props.data.data;
        const src = this.props.data.data.image;
        if(src) {
            if(src.match(/youtube.com/)) {
                return (
                    <CardMedia
                        src={src}
                        className={`${classes.media}`}
                        component="iframe"
                    />
                )
            } else if(src.match(/\/videos\//)) {
                return null;
                /*return (
                    //<iframe width="1280" height="720" src="https://www.youtube.com/embed/86hPVrUKkUc" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    <CardMedia
                        src={"https://www.youtube.com/embed/86hPVrUKkUc"}
                        //image={src}
                        //key={`${data.title}_img${index}`} 
                        className={`${classes.media} fb-video`}
                        //alt={`${data.title}_img${index}`}
                        data-href={src} 
                        data-width="500" 
                        data-show-text="false"
                        component="iframe"
                    />
                    /*
                        <blockquote cite={src} className="fb-xfbml-parse-ignore">
                            <a href={src}>{data.title}</a>
                            <p>{data.title}</p>
                            Posted by <a href="https://www.facebook.com/woodleaestate/">Woodlea</a>on {dateOutput}
                        </blockquote>
                    </CardMedia>*/
                //)
            } else {
                return (
                    this.state.showMedia && (
                        <CardMedia
                            image={src}
                            //key={`${data.title}_img${index}`} 
                            className={`${classes.media}`}
                            title={data.title}
                            //alt={`${data.title}_img${index}`}
                            onError={this.handleMediaError}
                        /> 
                    )
                )
               
                /*
                axios.get(src)
                    .then(response=>{
                        console.log(src, response);
                        if(response.headers["content-type"].includes('image'))
                            if(response.request.res.responseURL)
                                return (
                                    <CardMedia
                                        image={response.request.res.responseURL}
                                        //key={`${data.title}_img${index}`} 
                                        className={`${classes.media}`}
                                        title={data.title}
                                        //alt={`${data.title}_img${index}`}
                                    /> 
                                );
                            else
                                return (
                                    <CardMedia
                                        image={src}
                                        //key={`${data.title}_img${index}`} 
                                        className={`${classes.media}`}
                                        title={data.title}
                                        //alt={`${data.title}_img${index}`}
                                    /> 
                                );
                        else return (
                            <CardMedia
                                image={response.data}
                                //key={`${data.title}_img${index}`} 
                                className={`${classes.media}`}
                                title={data.title}
                                //alt={`${data.title}_img${index}`}
                            /> 
                        )
                    })
                    .catch(e=>{
                        //console.log(e);
                        return null;
                    });*/
            }
        }

        /*
        // Swiper Output
        if(this.props.data.media)
            if(this.props.data.media.length > 1) {
                // Get Media
                const mediaOutput = this.props.data.media.map((media, index)=>{
                    switch(media.type) {
                        case MEDIA.IMAGE:
                            return (
                                <img
                                    src={media.url}
                                    key={`${this.props.data.data.title}_img${index}`} 
                                    className={`${classes.media}`}
                                    alt={`${this.props.data.data.title}_img${index}`}
                                />
                            )
                        default: 
                            return (
                                <img
                                    src={media.url}
                                    key={`${this.props.data.data.title}_img${index}`} 
                                    className={`${classes.media}`}
                                    alt={`${this.props.data.data.title}_img${index}`}
                                />
                            )
                    }
                });

                // Swiper Config
                const swiperConfig = {
                    shouldSwiperUpdate: true,
                    setWrapperSize: true,
                    //autoHeight: true,
                    slidesPerView: '1',
                    effect: "slide",
                    direction: "horizontal",
                    loop: true,
                    autoplay: {
                        delay: 5000,
                    },
                    containerClass: classes.mediaSwiper,
                    wrapperClass: classes.mediaSwiperWrapper,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true,
                        //modifierClass: classes.mediaSwiperPagination,
                        //bulletClass: classes.mediaSwiperPaginationBullet,
                        //bulletActiveClass: `${classes.mediaSwiperPaginationBullet} active`,
                    },
                    preloadImages: true,
                    // Enable lazy loading
                    //lazy: true,
                    //slideClass: classes.media
                }

                return (
                    <Swiper
                        {...swiperConfig}
                        //ref={node=>{if(node)this.swiper = node.swiper}}
                    >
                        {mediaOutput}
                    </Swiper>   
                )
            } else {
                return (
                    <CardMedia
                        image={src}
                        key={`${data.title}_img${index}`} 
                        className={`${classes.media}`}
                        alt={`${data.title}_img${index}`}
                        title={data.title}
                    /> 
                )
            }
        }

        

        
        } else if(mediaOutput.length === 0) {
            return (
                <div className={classes.scrollContainer}>{mediaOutput}</div>
            )
        } else {
            return null;
        }*/
        
    }
    handleClick = () => {
        this.props.handleClick(this.props.index)
    }
    /*renderMarker = () => {
        const classes = this.props.classes;
        const fileName = this.props.data.data.category.toLowerCase().replace(/[\s]+/, '-');
        import(/* webpackMode: "eager", webpackChunkName: "cat-image" */  /*`../../assets/icons/categories/cat_${fileName}.png`)
        .then(src=>{
            return (<img src={src} />);
        //})
    }*/
    render() {        
        const classes = this.props.classes;
        const data = this.props.data.data;
        let index = this.props.index;
        /*const categoryClasses = classNames(
            classes.category,
            {
                'test': data.category === 'Test',
            }
        );*/

        // Get date output
        const date = data.timestamp.toDate();
        const AMPMOutput = (data.isTime && date.getHours() >= 12) ? 'PM' : 'AM';
        const timeOutput = (data.isTime) ? `\n${date.getHours()%12}:${('0'+date.getMinutes()).slice(-2)} ${AMPMOutput}`: '';
        const dateOutput = `${months[date.getMonth()]} ${date.getDate()}, 20${date.getYear().toString().slice(-2)}`
        const dateAttr = moment(date).format('YY-MM-W');//`20${date.getYear().toString().slice(-2)}-${('0' + (date.getMonth()+1)).slice(-2)}`;

        const fileName = this.props.data.data.category.toLowerCase().replace(/\s/g, '-');
        
        const headerClasses = classNames(
            classes.header,
            {
                [classes.clickable]: this.props.hasClick,
            }
        );
        return (
            <div className={classes.root} data-date={dateAttr}>
                <span className={classes.dateContainer}>{dateOutput}</span>
                <span className={classes.markerContainer}>
                    <img className={classes.marker} src={`./assets/icons/categories/cat_${fileName}.png`} />
                    <span style={{backgroundColor: window.categories[data.category]}} className={classes.timeline}/>
                </span>
                {
                    //<span className={classes.marker}><MarkerIcon className={classes.category} /></span>
                }
                <div className={classes.activityContainer}>
                    <Card elevation={5} key={`activity_${index}_item`} className={classes.activity}>                    
                        <CardHeader
                            className={headerClasses}
                            style={{borderBottom: `0.1em solid ${window.categories[data.category]}`,}}
                            title={data.title}
                            /*
                            subheader={dateOutput}*/
                            subheaderTypographyProps={{color:"secondary"}}
                            onClick={this.handleClick}
                        />
                        {/*data.image &&
                            <CardMedia className={`${classes.media}`} src={data.image} image={data.image} />
                        */}
                        {this.renderMedia(dateOutput)}
                        {//{data.image && <Media src={data.image} />}
                        }<CardContent>
                            <Typography color="textPrimary" variant="body1">{data.description}</Typography>
                            <Typography color="textPrimary" variant="body1">{timeOutput}</Typography>
                            <Typography color="textPrimary" variant="caption" style={{marginTop: "0.5em"}}>{dateOutput}</Typography>
                        </CardContent>
                    </Card>
                    <Chip 
                        label={<Typography color="textSecondary">{data.category}</Typography>} 
                        style={{backgroundColor: window.categories[data.category]}} 
                        className={classes.category} 
                    />
                </div>
            </div>
        );
    }    
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Item);

