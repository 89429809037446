/* ================================================================ */
// STORE | index.js
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import createDebounce from "redux-debounced";
import reducers from '../reducers';
/* ========================================== */
// Middleware
/* ========================================== */
let middlewareArr = [createDebounce(), promise(), thunk];
if(process.env.NODE_ENV !== 'production') {
    middlewareArr = [...middlewareArr, logger];
}
const middleware = applyMiddleware(...middlewareArr)
/* ========================================== */
// Exports
/* ========================================== */
export function configureStore(initialState = {}) {
    const store = createStore(
        reducers,
        initialState,
        applyMiddleware(thunk)
    )
    return store;
};
export default createStore(reducers, middleware);
