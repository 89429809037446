import {FirebaseDB, FirebaseStorage} from '../Firebase';

export const UPDATE_SCREEN = 'UPDATE_SCREEN';

export const FETCH_PAGES = 'FETCH_PAGES';
export const FETCHED_PAGES = 'FETCHED_PAGES';

export const FETCH_MENU = 'FETCH_MENU';
export const FETCHED_MENU = 'FETCHED_MENU';

/**
 * Window Resize
 * -------------
 * Updates screen width and height
 */
export function windowResize(width, height) {
    return (dispatch) => {
        const screen = {width: width, height: height}
        dispatch({type: UPDATE_SCREEN, payload: screen});
    }
}
/**
 * Fetch Pages
 * -----------
 * Retrieves all pages in the pages
 * collection and saves the response data to state
 */
export function fetchPages() {
    return (dispatch) => {
        dispatch({type: FETCH_PAGES});
        FirebaseDB.getCollection('pages')
        .then(snapshot=>{
            var dataPromises = [];
            snapshot.forEach(doc=>{
                dataPromises.push(new Promise(resolve=>{
                    const pageData = doc.data();
                    resolve(pageData);
                }))
            })
            Promise.all(dataPromises)
            .then(res=>{
                dispatch({type: FETCHED_PAGES, payload: res});
            })
            .catch(error=>console.log(error));
        })
        .catch(error=>console.log(error));
    }
}
/**
 * Fetch Menu
 * ----------
 * Queries the pages collection for main menu item
 * pages identified by "mainMenuItem" fields which are 
 * true in value, and saves the response array to state
 */
export function fetchMenu() {
    return (dispatch) => {
        dispatch({type: FETCH_MENU});
        //FirebaseDB.queryData('pages', {field: 'mainMenuItem', operator: '==', argument: true})
        FirebaseDB.getCollection('pages', {field:'mainMenuItem', order:'asc'})
        .then(snapshot=>{
            var dataPromises = [];
            snapshot.forEach(doc=>{
                dataPromises.push(new Promise(resolve=>{
                    const data = doc.data();
                    console.log(data)
                    resolve({label: data.title, path: data.path});
                }));
            })
            Promise.all(dataPromises)
            .then(res=>{
                dispatch({type: FETCHED_MENU, payload: res});
            })
            .catch(error=>console.log(error));
        })
        
    }
}

