/* ================================================================ */
// PANELS CONTAINER | Panels.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
//import Swiper from 'react-id-swiper';
import Swiper from 'swiper';
// Actions
import { fetchPanels } from "../actions/DataActions";
import { updateMapView, updateMapStyle } from "../actions/MapActions";
// Material UI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ScrollUpIcon from '@material-ui/icons/ArrowDropUp';
import ScrollDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
// ChartJS
import {Chart as ChartJS} from 'chart.js';
// Local Components
import Scrollbar from '../components/Scrollbar';
// Assets
import theme, {remToPx} from '../styles/theme';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        display:"flex"
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center"
    },
    loading: {
        width: "50%",
    },
    // === Swiper ===
    swiperContainer: {
        position: "relative",
        height: '100%',
        width: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.background.dark
    },
    swiperWrapper: {},
    // === Panel Classes ===
    panel: {
        minHeight: `calc(100vh - ${theme.components.header.height})`,
        //height: `calc(100% - ${theme.components.header.height})`,
        fontSize: "1em",
        [theme.breakpoints.down("lg")]: {
            fontSize: "0.9em",
            lineHeight: "1.25em"
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "0.8em"
        },
        color: theme.palette.secondary.contrastText,
        width: "100%",
        backgroundColor: theme.palette.background.dark,
        borderRadius: 0,
        //padding: "0.5rem 0",
        //height: "calc(100% - 1rem)"
        //margin: "0.5rem",
        '& .grid': {
            display: 'grid',
            
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '1em',
            gridAutoRows: 'auto'
        },
        '& .header-grid': {
            gridColumn: 1,
            gridColumnEnd: 4
        },
        '& .grid-item': {
            border: '0.25em solid #EE7C12',
            borderRadius: '1em',
            padding: '1em 2em'
        },
        '& h2': {
            marginTop: "1em",
            marginBottom: "0.5em",
            textTransform: "uppercase",
            fontSize: "1.25em",
            fontWeight: "bold",
            color: "rgba(255,255,255,0.5)",
        },
        '& h3': {
            textTransform: "uppercase",
            fontSize: "inherit",
            fontWeight: "bold",
        },
        '& p': {
            fontSize: "inherit",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            lineHeight: 1.25
        },
        '& .image': {
            display: "inherit",            
            width: "100%",
            height: "16em",
            backgroundColor: theme.palette.secondary.main,
            //height: "20vh",
            //maxHeight: "16em",
            marginTop: "1em",
            /*width: "70%",
            margin: "0 auto"*/
            [theme.breakpoints.down("sm")]: {
                display: "none"
            },
        },
        '& .category': {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "1em",
            //marginBottom: "1em",
            '& img': {
                width: "2.5em",
                height: "2.5em",
                objectFit: 'contain',
                marginRight: "1em"
            },
            '& div': {
                width: 'calc(100% - 2.5em)'
            }
        },
        '& .quote': {
            fontStyle: "italic",
            color: "rgba(255,255,255,0.75)",
            paddingLeft: "0.5em",
            borderLeft: "solid 0.1em white"
        },
        '& .hope': { borderColor: "#E57373" },
        '& .family': { borderColor: "#4EB6AC" },
        '& .appreciation': { borderColor: "#FFD54F" },
        '& .community': { borderColor: "#EA5456" },
        '& .travel': { borderColor: "#A56BAA" },
        '& .shopping': { borderColor: "#EF7EA9" },
        '& .employment': { borderColor: "#37A3DD" },
        '& .safety': { borderColor: "#5EB56A" },
        '& .trust': { borderColor: "#EE6F49" },
        '& .fatigue': { borderColor: "#61C4E3" },
        '& .networks': { borderColor: "#465A64" },
        '& .environment': { borderColor: "#8E6F64" },
        '& .resident-roles': { borderColor: "#038390" },
        '& .woodlea': { borderColor: "#EF7C13" },
        '& .infrastructure': { borderColor: "#03828F" },
        '& .sport': { borderColor: "#A46BA9" },
        '& .culture': { borderColor: "#B5B4B5" },
        '& .balance': { borderColor: "#A081BA" },
        '& .topics-that-matter': { borderColor: "grey" },
        '& .caption': {
            textAlign: "right",
            fontStyle: "italic",
            fontSize: "1em",
            color: "rgba(255,255,255,0.5)",
        }
    },
    content: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        //paddingTop: "1em"
    },
    panelOut: {},
    // === Scroll Prompts ===
    scrollButtonContainer: {
        zIndex: 1,
        position: "absolute",
        width: "calc(100% - 1rem)",
        height: "3rem",
        textAlign: "center",
        margin: "0 auto",
        '&.top': {
            top: 0,
            //background: "linear-gradient(to bottom, rgba(43, 43, 43, 1) 0%, rgba(43, 43, 43, 0.8) 75%, rgba(43, 43, 43, 0) 100%)"
        },
        '&.bottom': {
            bottom: 0,
            background: "linear-gradient(to top, rgba(50,62,67,1) 0%, rgba(50,62,67,0.8) 75%, rgba(50,62,67,0) 100%)"
        },
        '&.hidden': {
            visibility: "hidden"   
        }
    },
    scrollButtonContainerHide: {
        visibility: "hidden"
    },
    scrollButton: {
        width: "2rem",
        height: "2rem",
        margin: 0,
        padding: 0
    },
    scrollPrompt: {
        position: "relative",
        color: theme.palette.text.secondary,
        display: "block",
        fontSize: "1rem",
        '&.top': {
            top: "-0.5rem"
        },
        '&.bottom': {
            bottom: "-0.5rem",
        },
    },
    scrollIcon: {
        fontSize: "2rem",
    },
});
/* ========================================== */
/**
 * Panels
 * ------
 * Panels container
 * 
 * @param {object} classes style classes
 * @param {string} story story id
 * @param {number} panel panel index
 */
/* ========================================== */
class Panels extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        //story: PropTypes.string,
        panels: PropTypes.arrayOf(PropTypes.object),
        panel: PropTypes.number,
        /*screen: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }),*/
    }
    static defaultProps = {
        panel: 0
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        // Chart Ref
        this.charts = [];
        this.content;
        // Calculated Colours
        this.gradients = [];
        this.borders = [];
        // Swiper
        this.swiper;
        this.swiperContainer = React.createRef();
        this.state = {
            panel: this.props.panel,
            labels: this.props.labels,
            survey: '2017',
            updated: false
        }
    }
    componentDidMount() {
        if(!this.swiper && this.swiperContainer && this.swiperContainer.current) {
            //console.log(this.swiperContainer.current);
            this.swiper = new Swiper(ReactDOM.findDOMNode(this.swiperContainer.current), {
                lazy: true,
                shouldSwiperUpdate: true,
                effect: 'slide',
                slidesPerView: 'auto',
                direction: 'vertical',
                mousewheel: true,
                autoHeight: true,
                slideToClickedSlide: true,
                //preventInteractionOnTransition: true,
                // Free Mode Settings
                freeMode: true,
                freeModeMomentumRatio: 0.6,
                //freeModeMomentumVelocityRatio: 1,
                //freeModeSticky: true,
                freeModeMomentumBounce: false,
                freeModeMinimumVelocity: 0.33,
                resistance: true,
                resistanceRatio: 0.6,
                longSwipes: false,
                preloadImages: true,
                updateOnImagesReady: true,
                spaceBetween: 16,
                /*containerClass: this.props.classes.swiperContainer,
                wrapperClass: this.props.classes.swiperWrapper,
                slideClass: this.props.classes.panel,
                slideNextClass: this.props.classes.panelOut,
                slidePrevClass: this.props.classes.panelOut,*/
                setWrapperSize: true,
                grabCursor: true,
                observer: true,
                observeSlideChildren: true,
                /*runCallbacksOnInit: true,
                onInit: (swiper) => {
                    this.swiper = swiper
                }*/
                on: {
                    slideChange: this.handlePanelScroll,
                    observerUpdate: this.handlePanelUpdate,
                    //imagesReady: this.handlePanelUpdate
                }
            })
        }
        if(this.props.story === 'outcomes' && this.content && this.refs["chart1"]) {
            this.createGradients();
            this.renderChart();
        }
    }
    componentDidUpdate(prevProps) {
        if(this.swiper && this.props.panels.length > 0) {
            if(!this.state.updated) {
                this.handlePanelUpdate();
                this.setState({updated: true});
            }
            //console.log("PANEL CHECK", prevProps.panels !== this.props.panels)
            /*if(prevProps.panels !== this.props.panels) {
                if(this.props.story === 'outcomes' && this.content && this.refs["chart"]) {
                    this.createGradients();
                    this.renderChart();
                }
                console.log(this.props.panels)
                // Panels Update
                this.swiper.update();
                this.swiper.updateAutoHeight();
                this.swiper.slideTo(0);
                this.handlePanelScroll(0);
                
            }*/
        }
    }
    /* ======================== */
    // HANDLERS
    /* ======================== */
    handlePanelUpdate = () => {
        if(this.swiper)
            console.log("THIS SHOULD UPDATE");
            this.swiper.update()
    }
    /* ========================================== */ 
    /**
     * Handle Load
     * -----------
     * Handles onLoad actions for a given panel
     * @param {number} index panel index
     */
    /* ========================================== */ 
    /*handleLoad(index) {
        const panel = this.props.panels[index];
        if (panel.data.interactions && panel.data.interactions.onLoad.length > 0) {
            panel.data.interactions.onLoad.map(action => {
                if (action.type == "flyTo") {
                    this.props.updateMapView(action.mapView);
                } else if (action.type == "showDatasets") {
                    this.props.updateMapStyle(action.ids);
                }
            });      
        }        
    }*/
    /* ========================================== */ 
    /**
     * Handle Previous Panel
     * ---------------------
     * Triggers next panel via swiper reference
     */
    /* ========================================== */ 
    handlePrevPanel = () => {
        if(this.swiper)
            this.swiper.slidePrev();
    }
    /* ========================================== */ 
    /**
     * Handle Next Panel
     * -----------------
     * Triggers next panel via swiper reference
     */
    /* ========================================== */ 
    handleNextPanel = () => {
        if(this.swiper)
            this.swiper.slideNext();
    }
    /* ========================================== */
    /**
     * Handle Panel Scroll
     * -------------------
     * Sets current panel index in state for
     * internal ui checks
     */
    /* ========================================== */
    handlePanelScroll = () => {
        if(this.swiper) {
            const panel = this.swiper.activeIndex;
            this.setState({panel: panel}, ()=>{
                //this.handleLoad(panel)
            });
        }
    }
    /* ========================================== */ 
    /**
     * Handle Panel Index
     * ------------------
     * Triggers scroll to index on swiper
     * 
     * @param {number} index panel index to go to
     */
    /* ========================================== */ 
    handlePanelIndex = (index) => {
        if(this.swiper)
            this.swiper.slideTo(index);
    }
    resizeChart = () => {
        console.log("resize");
    }
    /* ========================================== */ 
    /**
     * Create Gradients
     * ----------------
     * Create all gradients from theme palette
     */
    /* ========================================== */ 
    createGradients = () => {
        const ctx = this.refs["chart1"].getContext('2d');
        //console.log(this.props.gradients);
        const gradientSpecs = theme.palette.gradient;
        //console.log(this.props.gradients === gradientSpecs);
        Object.keys(gradientSpecs).map((key, i) =>{
            // Changed gradient to be linear as edge bars dont get nice colours, ideally this could be coloured by bar height not canvas height       
            let gradient = ctx.createLinearGradient(0, 0, /*remToPx(theme.components.chart.width)*/300, 0);
            gradient.addColorStop(0,gradientSpecs[key].start);
            gradient.addColorStop(1,gradientSpecs[key].end);
            this.gradients[i] = gradient;

            let border = ctx.createLinearGradient(0, 0, /*remToPx(theme.components.chart.width)*/300, 0);
            border.addColorStop(0,gradientSpecs[key].start);
            border.addColorStop(0.25,gradientSpecs[key].end);
            border.addColorStop(0.5,gradientSpecs[key].start);
            border.addColorStop(0.75,gradientSpecs[key].end);
            border.addColorStop(1,gradientSpecs[key].start);
            this.borders[i] = border;
        });
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    renderChart() {
        const scales = {
            xAxes: [{
                ticks: {
                    fontColor: "white",
                    stepSize: 20,
                    beginAtZero: true
                },
                gridLines: {
                    color: "#151B1F"
                }
            }],
            yAxes: [{
                display: true,
                ticks: {
                    fontColor: "white",
                    stepSize: 1,
                    beginAtZero: true,
                },
                gridLines: {
                    color: "#151B1F",
                }
            }]
        }
        const chartConfigs = [
            {
                title: 'How satisfied are you with your life as a whole?', 
                datasets: [{label: "Australian Average (Mean) Score", data: [75.9, 0]}, {label: "Woodlea Average (Mean) Score", data: [82.5, 79.6]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with your standard of living?',
                datasets: [{label: "Australian Average (Mean) Score", data: [79.4, 0]}, {label: "Woodlea Average (Mean) Score", data: [83.1, 81.6]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with your health?',
                datasets: [{label: "Australian Average (Mean) Score", data: [73.2, 0]}, {label: "Woodlea Average (Mean) Score", data: [78.6, 76.3]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with what you are achieving in life?',
                datasets: [{label: "Australian Average (Mean) Score", data: [72.6, 0]}, {label: "Woodlea Average (Mean) Score", data: [81.4, 79.5]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with your personal relationships?',
                datasets: [{label: "Australian Average (Mean) Score", data: [78.6, 0]}, {label: "Woodlea Average (Mean) Score", data: [88.2, 84.5]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with how safe you feel?',
                datasets: [{label: "Australian Average (Mean) Score", data: [80.3, 0]}, {label: "Woodlea Average (Mean) Score", data: [67.8, 75.9]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with feeling part of your community?',
                datasets: [{label: "Australian Average (Mean) Score", data: [72.4, 0]}, {label: "Woodlea Average (Mean) Score", data: [78.2, 70.0]}],
                labels: ['2018', '2019']
            },
            {
                title: 'How satisfied are you with your future security?',
                datasets: [{label: "Australian Average (Mean) Score", data: [71.02, 0]}, {label: "Woodlea Average (Mean) Score", data: [70.0, 70.3]}],
                labels: ['2018', '2019']
            }
            
            
        ];
        const charts = chartConfigs.map(chartConfig=>{
            console.log(chartConfig);
            const datasets = chartConfig.datasets.map((dataset, i)=>{
                return {
                    label: dataset.label,
                    fillColor: this.gradients[i],
                    backgroundColor: this.gradients[i],
                    borderColor: this.borders[i],
                    borderWidth: 1,
                    hoverBackgroundColor: this.gradients[i],
                    hoverBorderColor: this.borders[i],
                    data: dataset.data
                };
            });
            const labels = ['2018', '2019'];
            return {...chartConfig, datasets};
        });
        console.log(charts);
        charts.forEach((chart, i)=>{
            const ctx = this.refs[`chart${i+1}`].getContext('2d');
            let options = {
                tooltips:{
                    displayColors:false,
                },
                legend: {
                    labels: {
                        fontColor: 'white',
                        textAlign: 'left',
                        boxWidth: 16
                    },
                    display: true,
                    onClick: ()=>{return;},
                    position: 'bottom',
                },
                scales: (scales),
                title: {
                    display: (chart.title),
                    text: chart.title,
                    fontColor: 'white'
                }
            }
            if(i < charts.length - 1)
                options.legend = { display: false }
            this.charts[i] = new ChartJS(ctx,{
                responsive: true,
                maintainAspectRatio: false,
                width: 500,
                height: 500,
                onResize: this.resizeChart,
                type: 'horizontalBar',
                data: {
                    labels: chart.labels,
                    datasets: chart.datasets,
                },
                options: options
            });
        })
        
    }
    // Render panels
    renderPanels() {
        const classes = this.props.classes;
        // Render Panels
        const panels = this.props.panels.map((panel, index)=>{
            const mediaOutput = panel.media.map((img, imgIndex)=>{
                return (
                    <img
                        src={img}
                        key={`${panel.data.title}_${imgIndex}`} 
                        className={`${classes.media}`}
                        alt={`${panel.data.title}_${imgIndex}`}
                    />
                )
            });
            return (
                <Card key={`panel_${index}`} className={`${classes.panel} swiper-slide`} elevation={10}>
                    {/*<CardHeader 
                        title={panel.data.title}
                        style={{paddingTop: "3rem"}}
                        //subheader={panel.data.date.toDate().toString() || panel.data.time.toDate().toString()}
                        //subheaderTypographyProps={{color:"secondary"}}
                    />*/}
                    <CardContent>
                        <div className={classes.content} dangerouslySetInnerHTML={{__html: panel.data.content}} ref={element => { if(this.props.story === 'outcomes' && index === 0) {this.content = element} }}/>
                        {(this.props.story === 'outcomes' && index === 0) &&
                            <div style={{marginTop: "3em"}}>
                                <h3 style={{textAlign: "center", marginBottom: "1em"}}>PWI Survey Results</h3>
                                <canvas ref="chart1" height="50" />
                                <canvas ref="chart2" height="50" />
                                <canvas ref="chart3" height="50" />
                                <canvas ref="chart4" height="50" />
                                <canvas ref="chart5" height="50" />                                
                                <canvas ref="chart6" height="50" />                            
                                <canvas ref="chart7" height="50" />                            
                                <canvas ref="chart8" height="65" />
                            </div>
                        }
                        {/*panel.data.description}
                        {mediaOutput.length > 0 &&
                            mediaOutput
                        */}
                    </CardContent>
                </Card>
            );
        });
        return panels;
    }
    // Main render
    render() {
        const classes = this.props.classes;
        if(this.props.panels.length > 0) {
            // Scrollbutton Classes
            /*const scrollButtonTopClasses = classNames(
                classes.scrollButtonContainer,
                'top',
                {
                    'hidden': this.state.panel === 0
                }
            )*/
            const scrollButtonBottomClasses = classNames(
                classes.scrollButtonContainer,
                'bottom',
                {
                    'hidden': this.state.panel === (this.props.panels.length - 1)
                }
            )
            return (
                <div className={classes.root}>
                    {/*<div className={scrollButtonTopClasses}>
                        <IconButton
                            color="primary"
                            className={classes.scrollButton + ' swiper-button-prev'}
                            onClick={this.handlePrevPanel}
                        >
                            <ScrollUpIcon style={{fontSize:"1.5rem"}} className={classes.scrollIcon} />
                        </IconButton>
                        <span className={classes.scrollPrompt + ' top'}>Scroll</span>
                    </div>*/}
                    {
                            //{...swiperParams}
                        }
                    <div className={`${classes.swiperContainer} swiper-container`}
                        ref={this.swiperContainer}
                    >
                        <div className={`${classes.swiperWrapper} swiper-wrapper`}>
                            {this.renderPanels()}
                        </div>
                    </div>
                    <div className={scrollButtonBottomClasses}>
                        <span className={classes.scrollPrompt + ' bottom'}>Scroll</span>
                        <IconButton
                            color="primary"
                            className={classes.scrollButton}
                            onClick={this.handleNextPanel}
                        >
                            <ScrollDownIcon style={{fontSize:"1.5rem"}} className={classes.scrollIcon} />
                        </IconButton>
                    </div>
                    <Scrollbar 
                        index={this.state.panel}
                        length={this.props.panels.length}
                        variant={"vertical"}
                        handleSelect={this.handlePanelIndex}
                    />
                </div>
            );
        } else {
            // No Panels
            return (<div className={classes.loadingContainer}><Typography variant="h6">No Panels</Typography></div>);
        }
    }
}
/* ========================================== */
// Redux
/* ========================================== */
/*const mapStateToProps = store =>{
    return { 
        loading: store.DataReducer.loading, 
        panels: store.DataReducer.panels,
        //screen: store.GlobeReducer.screen,
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({fetchPanels, updateMapView, updateMapStyle, dispatch}), dispatch)
}*/
/* ========================================== */
// Export
/* ========================================== */
export default /*connect(mapStateToProps, mapDispatchToProps)(*/withStyles(styles)(Panels);//);