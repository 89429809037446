export var mapConfig = {
    "MAPBOX": {
        "MAPBOX_TOKEN": "pk.eyJ1IjoiZ2ZvcmNlYm5lIiwiYSI6ImNpc2UyOGNvNDAwOHAybnZvdDE3Znl6dzQifQ.4U2v5GE_2qKpFOsS9nZqpA",
        "MAP_STYLE": "mapbox://styles/gforcebne/cjhg1lbfs09532skep5fbve8u",
        "INITIAL_COORDS": {
            "center": [144.662, -37.727],
            "zoom": 13.89,
            "bearing": 0,
            "pitch": 45.00
        }
    }
}