/* ================================================================ */
// SCROLLBAR COMPONENT | Scrollbar.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    // === Scroll Bar ===
    scrollbar: {
        cursor: "pointer",
        backgroundColor: theme.palette.secondary.main,
        display: "inline-block",
        verticalAlign: "top",
    },
    scrollbarVertical: {
        width: "1rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",

    },
    scrollbarHorizontal: {
        width: "100%",
        height: "1rem",
        display: "flex",
        flexDirection: "row"
    },
    scrollElement: {
        cursor: "pointer",
        outline: "none",
        backgroundColor: theme.palette.secondary.dark,
        padding: 0,
        margin: 0,
        border: "none",
        borderTop: `0.5em solid ${theme.palette.background.dark}`,
        transition: "all 0.5s ease",
        /*[theme.breakpoints.up("sm")]: {
            "&:hover": {
                backgroundColor: theme.palette.primary.light
            },
        },*/
    },
    scrollItemVertical: {
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
        WebkitAppearance: "none",
        width: "100%",
        height: "100%",
        border: "none",
        borderTop: `0.5em solid ${theme.palette.background.dark}`,
    },
    scrollItemHorizontal: {
        width: "100%",
        height: "100%",
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "0.05rem solid black",
        borderRight: "0.05rem solid black",
    },
    current: {
        backgroundColor: theme.palette.primary.main
    }
});
/* ========================================== */
// Scrollbar
// ---------
// Displays a scrollbar with sections relative
// to the current panel index and length
// PROPS:
//  classes: object - styles
//  index: number - current panel index
//  length: number - number of panels in story
//  handleSelect: function - sends selected element
//      index to parent function
/* ========================================== */
export class Scrollbar extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        index: PropTypes.number,
        length: PropTypes.number,
        variant: PropTypes.oneOf(["horizontal", "vertical"]),
        handleSelect: PropTypes.func
    }
    static defaultProps = {
        index: 0,
        length: 1,
        variant: "horizontal"
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        this.VARIANTS = {
            HORIZONTAL: "horizontal",
            VERTICAL: "vertical"
        }
    }
    /* ======================== */
    // HANDLERS
    /* ======================== */
    /* ========================================== */
    // Handle Click
    // ------------
    // Retrieves the value of the selected scroll
    // element and sends to parent function to update
    // the current panel index of the story
    // PROPS:
    //  event: click event
    /* ========================================== */ 
    handleClick = (event) => {
        // Get value of selected scroll element
        let value = parseInt(event.target.value);
        this.props.handleSelect(value);
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        let index = this.props.index;
        const length = this.props.length;
        // Populate Scroll Elements Array
        let elements = [];
        const scrollbarClasses = classNames(
            classes.scrollbar,
            {
                [classes.scrollbarHorizontal]: this.props.variant === this.VARIANTS.HORIZONTAL,
                [classes.scrollbarVertical]: this.props.variant === this.VARIANTS.VERTICAL,
            }
        )
        for(let i = 0; i < length; i++) {
            let scrollElementClasses = classNames(
                classes.scrollElement,
                {
                    [classes.scrollItemHorizontal]: this.props.variant === this.VARIANTS.HORIZONTAL,
                    [classes.scrollItemVertical]: this.props.variant === this.VARIANTS.VERTICAL,
                    [classes.current]: index === i
                }
            );
            elements.push(
                <button 
                    key={i}
                    value={i}
                    onClick={this.handleClick}
                    className={scrollElementClasses}
                    //style={(this.props.variant === this.VARIANTS.VERTICAL) ? {height: `calc(${100 / length}% - 0.1rem)`} : {width: `${100 / length}%`}}
                />
            );
        }
        // Output Scrollbar
        return (
            <div className={scrollbarClasses}>
                {elements}
            </div>
        )
    }
}

// Export component
export default withStyles(styles)(Scrollbar);