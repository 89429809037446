/* ================================================================ */
// MAP CONTAINER | Map.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ResetIcon from '@material-ui/icons/SettingsBackupRestore';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { updateMapView, updateMapStyle } from "../actions/MapActions";
import Popup from '../components/Popup';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "black"
    },
    resetButton: {
        padding: 0,
        position: "absolute",
        color: "white",
        zIndex: 1,
        left: "1rem",
        bottom: "3rem",
        width: "3rem",
        maxWidth: "3rem",
        height: "3rem",
        maxHeight: "3rem",
        '& svg': {
            width: "3rem",
            maxWidth: "3rem",
            height: "3rem",
            maxHeight: "3rem",
        }
    }
});
/* ========================================== */
/**
 * MapContainer
 * ------------
 * Map container for spacial visualisation output
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 */
/* ========================================== */
class MapContainer extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        mapboxApiAccessToken: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
        screen: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }).isRequired,
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
        this.state = {
            popup: null
        }
        this.map = null;
        this.mapContainer = null;
        this.baseStyle = null;
        this.spriteCleanup = [];
    }


    addStyle = (newStyle) => {
        var currentStyle = this.map.getStyle();
        newStyle.sources[newStyle.name] = newStyle.sources.composite;
        newStyle.sources.composite = currentStyle.sources.composite;
        newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources); // ensure any sources from the current style are copied across to the new style
        newStyle.layers = newStyle.layers.filter( lyr => lyr.id != 'mapbox-satellite' && lyr.id != 'background' && lyr.id != 'woodlea-raster');
        newStyle.layers = newStyle.layers.map ( (lyr) => {
            lyr.source = newStyle.name;
            return lyr;
        });
        newStyle.layers = [...currentStyle.layers, ...newStyle.layers];
        newStyle.sprite = currentStyle.sprite;

        this.baseStyle = currentStyle;
        
        this.map.loadImage(this.props.spriteImage, (error,image) => {

            console.log('IMAGE TO LOAD', image);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0)
            
            for (var key in this.props.spriteJSON) {
                console.log(key, this.props.spriteJSON[key]);
                var imgData=ctx.getImageData(this.props.spriteJSON[key].x,this.props.spriteJSON[key].y,this.props.spriteJSON[key].width,this.props.spriteJSON[key].height);    
                //if(!this.map.hasImage(key))
                    this.map.addImage(`${key}`, imgData)
                this.spriteCleanup.push(`${key}`);
            }

            console.log(currentStyle, newStyle);
            
            this.map.setStyle(newStyle); // now setStyle  
        })      
    }

    cleanUpStyle = () => {
        this.spriteCleanup.map( sprite => this.map.removeImage(sprite) );
        this.spriteCleanup = [];
        this.map.setStyle(this.baseStyle); // now setStyle
    }

    componentDidMount() {
        
        mapboxgl.accessToken = this.props.mapboxApiAccessToken;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.props.baseMapStyle,
            center: this.props.coords.center,
            zoom: this.props.coords.zoom,
            bearing: this.props.coords.bearing,
            pitch: this.props.coords.pitch
        });
        this.map.on('click', this.handleClick);
        this.map.on('move', this.handleViewUpdate); 
        this.props.updateMapStyle(["cjn0wnypt5jqs2rqrzps7wbnz"])
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapViewState != this.props.mapViewState) {
            
            this.map.flyTo({
                center: this.props.mapViewState.center,
                zoom: this.props.mapViewState.zoom,
                bearing: this.props.mapViewState.bearing,
                pitch: this.props.mapViewState.pitch
            });
        }
        if (prevProps.mapStyle != this.props.mapStyle) {
            if (this.baseStyle != null) {
                this.cleanUpStyle();
            }
            this.addStyle(this.props.mapStyle);
        }
    }

    handleMapReset = () => {
        /*this.map.flyTo({
            center: this.props.coords.center,
            zoom: this.props.coords.zoom,
            bearing: this.props.coords.bearing,
            pitch: this.props.coords.pitch
        })*/
        // dodgey hack to cope with the whack reducer
        this.props.updateMapView({
            ...this.props.coords, 
            center: {
                longitude: this.props.coords.center[0], 
                latitude: this.props.coords.center[1]
            }
        });
        this.props.updateMapStyle(this.props.baseMapStyle);
    }
    
    /* ========================================== */
    /**
     * Handle Click
     * ------------
     * Handles map click event
     * 
     * @param {object} object clicked object data
     * @param {event} event scroll wheel event
     */
    /* ========================================== */
    handleClick = ({point, lngLat}) => {
        this.setState({popup: null}, ()=>{
            const pointArr = [point.x, point.y];
            console.log(pointArr);
            if(this.map) {
                var features = this.map.queryRenderedFeatures(pointArr, {layers: ['map-locations', 'woodlea-icons'] /*props.loadedLayers*/});//'nsw-lga-polygongeojson']});
                if (features.length > 0) {
                    console.log(features);   
                    const anchor = {lat: features[0].properties.lat || lngLat.lat, lng: features[0].properties.lon || lngLat.lng};
                    const coords = this.map.project(anchor);
                    const titleKey = Object.keys(features[0].properties).find(key=>/(Name)|(Desc)|(title)/.test(key)); 
                    const content = {
                        title: features[0].properties[titleKey] || null,
                        image: null,
                        contact: null,
                        summary: null //features[0].properties['Desc'] || null
                    };
                    this.setState({popup: {coords, anchor, content}}, ()=>console.log(this.state.popup));
                }  
            }
        });
       
    }

    handleViewUpdate = (e) => {
        // Update active 
        if(this.state.popup) {
            const coords = this.map?this.map.project(this.state.popup.anchor):null;
            this.setState({popup: {...this.state.popup, coords}});
        }
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        return (
            <>
                {//<Button onClick={()=>{console.log("MAP ZOOM:", this.map.style.z)}}>Test</Button>
                }
                <div key="map" ref={el => this.mapContainer = el} className={classes.root + " mapbox-container"}>
                    {this.state.popup && (
                        <Popup {...this.state.popup} />
                    )}
                </div>
                <IconButton key="btn_reset" className={classes.resetButton} onClick={this.handleMapReset} ><ResetIcon /></IconButton>
            </>
        )
    }
}
/* ========================================== */
// Redux
/* ========================================== */
const mapStateToProps = store =>{
    return { 
        screen: store.GlobeReducer.screen,
        mapViewState: store.MapReducer.mapViewState,
        mapStyle: store.MapReducer.mapStyle,
        spriteJSON: store.MapReducer.spriteJSON,
        spriteImage: store.MapReducer.spriteImage
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({updateMapView, updateMapStyle, dispatch}), dispatch)
}
/* ========================================== */
// Export
/* ========================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapContainer));