//import update from "react-addons-update";
import * as MapActions from '../actions/MapActions';

export default function reducer(
    state = {
        mapViewState: {
            center: [0,0],
            bearing: 0,
            pitch: 0,
            zoom: 0
        },
        mapStyle: [],
        accessToken: 'pk.eyJ1IjoiZ2ZvcmNlYm5lIiwiYSI6ImNpc2UyOGNvNDAwOHAybnZvdDE3Znl6dzQifQ.4U2v5GE_2qKpFOsS9nZqpA',
        mapboxUserID: 'gforcebne',
        spriteJSON: null,
        spriteImage: null,

    }, action) {
        switch (action.type) {
            case MapActions.UPDATE_MAPVIEW: { 
                var updatedMapView = {
                    center: [action.payload.center.longitude,action.payload.center.latitude] || state.mapViewState.center,
                    zoom: action.payload.zoom || state.mapViewState.zoom,
                    bearing: action.payload.bearing || state.mapViewState.bearing,
                    pitch: action.payload.pitch || state.mapViewState.pitch
                }
                return {...state, mapViewState: updatedMapView}
            }
            case MapActions.UPDATE_STYLES: {
                return {...state, mapStyle: action.payload.style, spriteJSON: action.payload.spriteJSON, spriteImage: action.payload.spriteImage } 
            }
            case MapActions.DATA_ERROR: {
                return state;
            }
        }
    return state;
}