import React from "react";
import ReactDOM from 'react-dom';
//import {FirebaseDB} from './Firebase';
import { Provider } from "react-redux";
// Store
import store from "./store";
// Actions
import { windowResize } from "./actions/GlobeActions";
// Local Components
import Layout from './containers/Layout';
// Assets -- Moved to index.html
/*import './styles/reset.css';
import './styles/mapbox.css';
import './styles/styles.css';*/

const app = document.getElementById('app');
/* ========================================== */
// Window Events
/* ========================================== */
var timeout = false;
var delay = 250;
function resize() {
    store.dispatch(windowResize(window.innerWidth, window.innerHeight))
}
if(window.navigator.userAgent.match("Android")) {
    window.addEventListener('orientationchange', ()=>{
        store.dispatch(windowResize(window.innerWidth, window.innerHeight))
    })
} else {
    window.addEventListener('resize', () => {
        // clear the timeout
        clearTimeout(timeout);
        // start timing for event "completion"
        timeout = setTimeout(resize, delay);
    })
}
window.addEventListener('load', () => {
    store.dispatch(windowResize(window.innerWidth, window.innerHeight))
})
window.categories = {
    'Hope': '#E57373',
    'Family': '#4EB6AC',
    'Appreciation': '#FFD54F',
    'Community': '#EA5456',
    'Travel': '#A56BAA',
    'Shopping': '#EF7EA9',
    'Employment': '#37A3DD',
    'Safety': '#5EB56A',
    'Trust': '#EE6F49',
    'Fatigue': '#61C4E3',
    'Networks': '#465A64',
    'Environment': '#8E6F64',
    'Resident Roles': '#038390',
    'Woodlea': '#EF7C13',
    'Topics that matter': 'grey',
    'Action':'#FFF',
    'Education': '#37A2DC',
    'Infrastructure': '#03828F',
    'Culture': '#B5B4B5',
    'Balance': '#A081BA'
    //'Employment Opportunities': '#37A3DD'
}
/* ========================================== */
// Render
/* ========================================== */
ReactDOM.render(
    <Provider store={store}>
        <Layout />
    </Provider>,
app);