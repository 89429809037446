/* ================================================================ */
// FIREBASE STORAGE FUNCTIONS | Storage.js
/* ================================================================ */
/* ========================================== */
// IMPORTS
/* ========================================== */
import {storage} from './Firebase';
const storageRef = storage.ref();
/* ======================== */
// UPLOAD FILE
/* ======================== */
/* ========================================== */
/**
 * Upload File
 * -----------
 * Uploads file to firebase storage
 * with specified path and metadata
 * 
 * @param {string} path reference to file including filename
 * @param {string} metadata file metadata including custom properties
 * @param {File} file file 
 */
/* ========================================== */
export const uploadFile = (path, metadata, file) => {
    var uploadTask = storageRef.child(path).put(file,metadata);
    return uploadTask; 
}
/* ======================== */
// GET FILE
/* ======================== */
/* ========================================== */
/**
 * Download File from Path
 * -----------------------
 * Obtain the download url from
 * firebase storage using storage path
 * 
 * @param {string} path path to file
 * 
 * @return {Promise} url download promise
 */
/* ========================================== */
export const downloadFileFromPath = (path) => {
    const pathRef = storage.ref(path);
    return pathRef.getDownloadURL();
}
/* ========================================== */
/**
 * Download File from GS URL
 * -------------------------
 * Obtain the download url from
 * firebase storage using the gs url
 * 
 * @param {string} url gs url
 * 
 * @return {Promise} url download promise
 */
/* ========================================== */
export const downloadFileFromGSURL = (url) => {
    const gsRef = storage.refFromURL(url);
    return gsRef.getDownloadURL();
}