/* ========================================== */
// Imports
/* ========================================== */
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Material UI
import withStyles from "@material-ui/core/styles/withStyles";
// Local Components
import MyGlobeIcon from './MyGlobeIcon';
// Assets
import {Woodlea, Aintree, QUT} from '../../assets/svgs';
/* ========================================== */
// Styles
/* ========================================== */
const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "1rem",
        textDecoration: "none"
    },
    logo: {
        width: "50%",
        marginLeft: "1rem"
    },
    subBranding: {
        display: "flex",
        width: "33%",
        textAlign: "right"
    }
});
/* ========================================== */
/**
 * Branding Component
 * ------------------
 * Outputs Logo and MyGlobe Trademark
 * 
 * @param {object} classes styles
 */
/* ========================================== */
class Branding extends PureComponent {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        return (
            <Link to='/' className={classes.root}>
                <img
                    alt={"Aintree Logo"} 
                    className={classes.logo}
                    src={"data:image/svg+xml;base64," + btoa(Aintree)}
                />
                <span className={classes.subBranding}>
                    <img
                        alt={"Woodlea Logo"}
                        style={{width: "70%", marginRight: "1rem"}}
                        src={"data:image/svg+xml;base64," + btoa(Woodlea)}
                    />
                    <img
                        alt={"QUT Branding"} 
                        src={"data:image/svg+xml;base64," + btoa(QUT)}
                    />
                </span>
                {//<MyGlobeIcon />
                }
            </Link>
        )
    }
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Branding);