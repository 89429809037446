/* ================================================================ */
// TimelineGraph COMPONENT | Login.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
// Local Components
import StackedArea from './StackedArea';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        color:'#FFF',
        position:'relative',
        height:'100%',
    },
    scroller:{
        borderRight:`1px solid ${theme.palette.primary.main}`,
        boxSizing:'border-box',
        height:'100%',
        //width:'2px',
        marginLeft:'-1px',
        position:'absolute',
        transition:'left ease-in-out 1s',
        pointerEvents:'none',
    },
    graphContainer:{
        position:'absolute',
    },
    dateContainer: {
        display:'flex',
        width:'100%',
        flexFlow: 'row nowrap',
        position:'absolute',
        bottom:0,
        boxSizing:'border-box',
        borderTop:'2px solid #FFF',
    },
    date: {
        width:'100%',
        height:'2em',
        lineHeight:'2em',
        fontSize:'.6em',
        textAlign:'center',
        textTransform:'uppercase'
    },
    surveyDate: {
        position:'absolute',
        top:'-10px',
        width: 0, 
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',        
        borderBottom: '10px solid #FFF',
        fontSize:'.6rem',
        textAlign:'center',
        '& .surveyInner': {
            display:'none',
            position:'absolute',
            top:'-16px',
            left:0,
            padding:'2px',
            background:'rgba(0,0,0,.4)',
            color:'#FFF',
            transform:'translateX(-50%)',
        },
        '&:hover .surveyInner': {
            display:'block',
        }
    }
});
/* ========================================== */
/**
 * Timeline Graph
 * -----
 * Firebase login component
 * 
 * @param {object} classes style classes
 */
/* ========================================== */
class TimelineGraph extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        data:PropTypes.array,
        keys:PropTypes.array,
        colors:PropTypes.object,
        scrollX:PropTypes.number,
        scrollW:PropTypes.number,
    }
    static defaultProps = {
        height:300,
        width:100,
        data:[
            {date: '2018-01', apples: 3840, bananas: 1920, cherries: 960, dates: 400},
            {date: '2018-02', apples: 1600, bananas: 1440, cherries: 960, dates: 400},
            {date: '2018-03', apples:  640, bananas:  960, cherries: 640, dates: 3000},
            {date: '2018-04', apples:  320, bananas:  480, cherries: 640, dates: 400},
            {date: '2018-05', apples:  640, bananas:  480, cherries: 640, dates: 350},
            {date: '2018-06', apples:  700, bananas:  200, cherries: 800, dates: 300},
            {date: '2018-07', apples:  900, bananas:  480, cherries: 640, dates: 400},
        ],
        keys:['apples','bananas','cherries','dates'],
        colors: {
            apples:'green',
            bananas:'yellow',
            cherries:'red',
            dates:'purple',
        },
        scrollX:0,
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }

    handleClick = (date)=>{        
        if(this.props.scrollToDate) {
            ga('send', {
                hitType: 'event',
                eventCategory: 'mg_content_interact',
                eventAction: 'graph_click',
                eventLabel: `timeline`,
                eventValue: `${date}`
            });
            this.props.scrollToDate(date);
        }
    }
    
    render() {
        const {classes, data, keys, colors, width, height, scrollX,scrollW,surveyDates} = this.props;
       let prev = "";    
        return (
            <div className={classes.root}>
                <div className={classes.graphContainer}>
                    <StackedArea height={height} width={width} data={data} keys={keys} colors={colors} handleClick={this.handleClick} />
                </div>
                <div className={classes.dateContainer}>
                    {
                        surveyDates.map(x=>{
                            return <div className={classes.surveyDate} style={{left:`${100/data.length*x.ind}%`}}><span className={'surveyInner'}>{x.title}</span></div>
                        })
                    }
                    {/*this will not line up completely with graph as x val starts at zero and ends at 100% but prob doesnt matter for this vis?*/ }
                    {data.map(x=>{
                        let cur = moment(x.date.substring(0,5),'YY-MM').format('MMM YY');                         
                        if(cur!=prev){
                            prev=cur;
                            return <div className={classes.date} key={x.date}>{moment(x.date.substring(0,5),'YY-MM').format('MMM')}</div>
                        } else {
                            //blanks to make months still line up with graph
                            return <div className={classes.date} key={x.date}>&nbsp;</div>
                        }
                        })}                        
                </div>
                <div className={classes.scroller} style={{left:`${scrollX}%`, width:`${scrollW}px`}}></div>
            </div>
        )
        
    }
}
export default withStyles(styles)(TimelineGraph);