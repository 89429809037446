/* ================================================================ */
// TimelineGraph COMPONENT | Login.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Material
import withStyles from '@material-ui/core/styles/withStyles';
// Other
import { Stack } from '@vx/shape';
import {scaleLinear} from '@vx/scale';
import {curveCardinal} from '@vx/curve';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        position:'absolute',
    },
    container: {
        
    },
    tooltip: {
        position:'fixed',
        background:'rgba(0,0,0,.4)',
        textAlign:'center',
        fontSize:'.6rem',
        borderRadius:'.3rem',
        padding:'.3rem',
        transform: 'translate(-50%,50%)',
        transition:'opacity ease-in .25s',
        pointerEvents:'none',
    }
});
/* ========================================== */
/**
 * Timeline Graph
 * -----
 * Firebase login component
 * 
 * @param {object} classes style classes
 */
/* ========================================== */
class StackedArea extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        //ymin:PropTypes.number,
        //ymax:PropTypes.number,
        data:PropTypes.array,
        keys:PropTypes.array,
        colcor:PropTypes.object,
    }
    static defaultProps = {
        width:300,
        height:100,
        data:[
            {date: 20180101, apples: 3840, bananas: 1920, cherries: 960, dates: 400},
            {date: 20180102, apples: 1600, bananas: 1440, cherries: 960, dates: 400},
            {date: 20180103, apples:  640, bananas:  960, cherries: 640, dates: 3000},
            {date: 20180104, apples:  320, bananas:  480, cherries: 640, dates: 400},
            {date: 20180105, apples:  640, bananas:  480, cherries: 640, dates: 350},
            {date: 20180106, apples:  700, bananas:  200, cherries: 800, dates: 300},
            {date: 20180107, apples:  900, bananas:  480, cherries: 640, dates: 400},
        ],
        keys:['apples','bananas','cherries','dates'],
        colors: {
            apples:'green',
            bananas:'yellow',
            cherries:'red',
            dates:'purple',
        },
        //ymin:0,
        //ymax:3840+1920+960+400+3000
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);      
        this.state = {
            tooltipLeft:0,
            tooltipTop:0,
            tooltipData:null,
            tooltipVisible:false,
        }  
    }
    
    render() {
        const {classes,width,height,data,keys,colors} = this.props;     
        let yMin = Infinity, yMax=-Infinity;
        for(let i=0; i<data.length; i++){
            let sum = 0;
            keys.forEach(x=>sum+=data[i][x]);
            if(sum<yMin)
                yMin = sum;
            if(sum>yMax)
                yMax = sum;
        }   
        yMax+=1;
        // Im not sure the best way to yScale this, need more data. not using yMin for now, using -yMax
        const xScale = scaleLinear({
            range: [0, width],
            domain: [0,data.length-1],
          });
          const yScale = scaleLinear({
            range: [height-10, 10],
            domain: [-yMax*.5, yMax*.5],
          });
        return (
            <div className={classes.root}>
            <svg width={width} height={height} 
                onClick={(e)=>{
                    e.persist();
                    const bounds = e.currentTarget.getBoundingClientRect();
                    const index = Math.round(xScale.invert(e.clientX-bounds.left))                                
                    if(this.props.handleClick)
                        this.props.handleClick(data[index].date);
                }} 
            >
                <Stack
                    data={data}
                    keys={keys}
                    offset="silhouette"
                    curve={curveCardinal}
                    x={(d, i) => {return xScale(i)}}
                    y0={d =>yScale(d[0])}
                    y1={d => yScale(d[1])}                    
                    render={({ seriesData, path }) => {
                    return seriesData.map((series, i) => {
                        return (
                        <g key={`series-${series.key}`}>
                            <path d={path(series)} fill={colors[series.key]} strokeWidth={2} stroke={'none'}
                            onMouseEnter={(e)=>{
                                e.target.setAttribute('stroke','#00ffd9');
                                const bounds = e.target.getBoundingClientRect();
                                const index = Math.round(xScale.invert(e.clientX-bounds.left))                                
                                this.setState(
                                    {
                                        tooltipLeft : e.clientX,
                                        tooltipTop : e.clientY,
                                        tooltipData : series.key==='Action'&&series[index].data.ActionData?series[index].data.ActionData.join('/'):series.key,
                                        tooltipVisible:true
                                    }
                                )
                            }} 
                            onMouseLeave={(e)=>{
                                e.target.setAttribute('stroke','none');
                                this.setState(
                                    {
                                        tooltipVisible:false
                                    });
                            }}                             
                            />                            
                        </g>
                        );
                    });
                    }}
                />
            </svg>
            <div className={classes.tooltip} style={{'opacity':(this.state.tooltipVisible?1:0),'top':this.state.tooltipTop,'left':this.state.tooltipLeft}}>{this.state.tooltipData}</div>
            </div>
        )
        
    }
}
export default withStyles(styles)(StackedArea);