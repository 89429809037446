/* ================================================================ */
// FIREBASE DATABASE FUNCTIONS | Database.js
/* ================================================================ */
/* ========================================== */
// IMPORTS
/* ========================================== */
import PropTypes from "prop-types";
import {database} from './Firebase';
/* ========================================== */
// Settings
/* ========================================== */
const settings = {timestampsInSnapshots: true};
database.settings(settings);
database.enablePersistence({experimentalTabSynchronization: true})
    /*.then(()=>{
        database.disableNetwork()
            .then(function() {
                console.log("Offline Mode");
            });
    })*/
    .catch(error=>{
        console.log(error);
    })
/* ========================================== */
// Functions
/* ========================================== */
/* ======================== */
// ADD DATA
/* ======================== */
/* ========================================== */
/**
 * Add Data
 * --------
 * Inserts formatted data object into a given 
 * collection reference, auto generating the
 * document id
 * 
 * @param {string} collection collection reference
 * @param {object} data data object
 * 
 * @returns {Promise} document insert promise
 */
/* ========================================== */
export const addData = (collection, data) => {
    return database.collection(collection).add(data);   
}
/* ======================== */
// GET DATA
/* ======================== */
/* ========================================== */
/**
 * Get Collection
 * --------------
 * Retrieves all documents for a given 
 * collection reference
 * 
 * @param {string} collection collection ref
 * @param {object} order field to order by and specified order (asc or desc)
 * @param {object} options get options
 * 
 * @returns {Promise} collection get response promise
 */
/* ========================================== */
export const getCollection = (collection, order, options) => {
    return (order) ? database.collection(collection).orderBy(order.field, order.order).get(options) : database.collection(collection).get(options);
}
getCollection.propTypes = {
    collection: PropTypes.string.isRequired,
    options: PropTypes.shape({
        source: PropTypes.string.isRequired
    })
}
getCollection.defaultProps = {
    options: {
        source: 'default'
    }
}
/* ========================================== */
/**
 * Get Subcollection
 * -----------------
 * Constructs references to subcollection destination
 * and returns firebase firestore promise
 * 
 * @param {string} collection name of parent collection
 * @param {string} document id of document in parent collection
 * @param {string} subcollection name of collection under given document
 * @param {object} order field to order by and specified order (asc or desc)
 * @param {object} options get options
 * 
 * @returns {Promise} subcollection get response promise
 */
/* ========================================== */
export const getSubCollection = (collection, document, subcollection, order, options) => {
    const parentCollectionRef = database.collection(collection);
    const documentRef = parentCollectionRef.doc(document);
    return (order) ? (documentRef.collection(subcollection).orderBy(order.field, order.order).get(options)) : (documentRef.collection(subcollection).get(options));
}
getSubCollection.propTypes = {
    collection: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    subcollection: PropTypes.string.isRequired,
    order: PropTypes.shape({
        field: PropTypes.string.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired
    }),
    options: PropTypes.shape({
        source: PropTypes.string.isRequired
    }) 
}
/* ======================== */
// QUERY DATA
/* ======================== */
export const queryData = (collection, query, options) => {
    return database.collection(collection).where(query.field, query.operator, query.argument).get(options);
}
queryData.propTypes = {
    collection: PropTypes.string.isRequired,
    query: PropTypes.shape({
        field: PropTypes.string.isRequired,
        operator: PropTypes.oneOf(['==', '>', '>=', '<']).isRequired,
        argument: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired
    }).isRequired,
    options: PropTypes.shape({
        source: PropTypes.string.isRequired
    })
}
queryData.defaultProps = {
    options: {
        source: 'default'
    }
}