/* ================================================================ */
// IFRAME PAGE COMPONENT | Iframe.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {},
    loadingContainer: {},
    loading: {},
});
/* ========================================== */
// Constants
/* ========================================== */
const urls = {
    'join': 'https://survey.ysquares.com.au/s.aspx?s=e07c894e-d957-4e66-be6d-c4ceb4761074&z=m&l=false'
}
/* ========================================== */
/**
 * Iframe Page
 * ------------
 * Iframe page component
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 * @param {string} page page name
 */
/* ========================================== */
class IframePage extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        screen: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }),
        page: PropTypes.string.isRequired
        //url: PropTypes.string.isRequired
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <iframe src={urls[this.props.page]} /*sandbox="allow-scripts"*/ width="100%" height="100%"/>
            </div>
        );
    }    
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(IframePage);

