/* ========================================== */
// IMPORTS
/* ========================================== */
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import red from '@material-ui/core/colors/red';
/* ========================================== */
/**
 * Rem to Pixel
 * ------------
 * Converts rem value to pixels
 * 
 * @param {number} rem rem value
 * 
 * @returns {number} calculated pixel value
 */
/* ========================================== */
export function remToPx(rem) {
    return parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size')) * parseFloat(rem);
}
/* ========================================== */
/**
 * Pixel to Rem
 * ------------
 * Convert Pixels to Rem Value
 * 
 * @param {number} px pixel value 
 * 
 * @returns {number} calculated rem value
 */
/* ========================================== */
export function pxToRem(px) {
    return parseFloat(px / parseInt(window.getComputedStyle(document.body).getPropertyValue('font-size')).toPrecision(4));
}
/* ========================================== */
/**
 * Theme
 * -----
 * Material UI Theme
 */
/* ========================================== */
var theme = createMuiTheme({
    palette: {
        common: {
            black: "#000",
            white: "#fff",
            transparent: "rgba(0, 0, 0, 0)",
            fullBlack: "rgba(0, 0, 0, 1)",
            darkBlack: "rgba(0, 0, 0, 0.87)",
            lightBlack: "rgba(0, 0, 0, 0.54)",
            minBlack: "rgba(0, 0, 0, 0.26)",
            faintBlack: "rgba(0, 0, 0, 0.12)",
            fullWhite: "rgba(255, 255, 255, 1)",
            darkWhite: "rgba(255, 255, 255, 0.87)",
            lightWhite: "rgba(255, 255, 255, 0.54)"
        },
        primary: {
            light: "#ffac64",
            main: "#ED7C36",
            dark: "#E94E1B",
            contrastText: "#FFFFFF"
        },
        secondary: {
            light: "#606060",
            main: "#1C272D",
            dark: "#151B1F",
            contrastText: "#EDEDED"
        },
        error: red,
        text: {
            primary: "#323E43",
            secondary: "#EDEDED",
            disabled: "rgb(255, 51, 0)",
            hint: "#757575",
            icon: "#231F20",
            divider: "#ED7C36",
            lightDivider: "#B2B2B2"
        },
        input: {
            bottomLine: "rgba(0, 0, 0, 0.42)",
            helperText: "rgba(0, 0, 0, 0.54)",
            labelText: "rgba(0, 0, 0, 0.54)",
            inputText: "rgba(0, 0, 0, 0.87)",
            disabled: "rgba(0, 0, 0, 0.42)"
        },
        action: {
            active: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.26)"
        },
        background: {
            default: "#DADADA",
            light: "#F6F6F6",
            dark: "#323E43",
            paper: "#FFFFFF",
            appBar: "#ED7C36",
            contentFrame: "#B2B2B2",
            overlay: "rgba(64, 64, 65, 0.8)",
            disabled: "rgba(0,0,0,)"
        },
        categories: {
            'Hope': '#E57373',
            'Family': '#4EB6AC',
            'Appreciation': '#FFD54F',
            'Community': '#EA5456',
            'Travel': '#A56BAA',
            'Shopping': '#EF7EA9',
            'Employment': '#37A3DD',
            'Safety': '#5EB56A',
            'Trust': '#EE6F49',
            'Fatigue': '#61C4E3',
            'Networks': '#465A64',
            'Environment': '#8E6F64',
            'Resident Roles': '#038390',
            'Woodlea': '#EF7C13',
            'Topics that matter': 'grey',
        },
        gradient: {
            1: {
                start: "#56C1AB",
                end: "#447BBE"
            },
            2: {
                start: "#FFDA3F",
                end: "#F7931E"
            },
        },
        /*categories: [
            "#F2C02F",
            "#14A797",
            "#EA5353",
            "#36A9E1",
            "#EC679F",
            "#ED7C36"
        ]*/
    },
    /* ========================================== */
    // Typography
    // ----------
    // Typography style settings
    /* ========================================== */
    typography: {
        useNextVariants: true,
        fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
        htmlFontSize: 16,
        fontSize: 16,
        /* ========================================== */
        // Typography Classes (Variants)
        /* ========================================== */
        // Headings
        h1: {
            fontSize: "2.375rem"
        },
        h2: {
            fontSize: "2rem"
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
        h4: {
            fontSize: "1rem",
            fontWeight: "bold",
        },

        // Body Formats
        body1: {
            fontSize: "1rem"
        },
        body2: {
            fontSize: "0.95rem"
        },
        // Caption Format
        caption: {
            fontSize: "0.75rem"
        },
        // Button (Back button)
        button: {
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.54)"
        },
    },
    /* ========================================== */
    // Layers
    // ------
    // Component zIndexes
    /* ========================================== */
    layers: {},
});

/* ========================================== */
// Overrides
// ---------
// MaterialUI component overrides
/* ========================================== */
theme.overrides = {
    MuiCard: {
        root: {
            //border: `0.1rem solid ${theme.palette.background.contentFrame}`
        }
    },
    MuiCardHeader: {
        root: {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem"
        }
    },
    /*MuiCardContent: {
        root: {
            paddingTop: 0
        }
    },
    MuiCardMedia: {
        root: {
            //marginBottom: "1em"
        }
    },*/
    MuiTab: {
        root: {
            minWidth: "2rem",
            whiteSpace: "nowrap",
            [theme.breakpoints.up('md')]: {
                minWidth: "2rem"
            }
        },
        labelContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: "1rem",
                paddingRight: "1rem"
            }
        }
    },
    MuiOutlinedInput: {
        root: {
            margin: "0.33rem 0"
        },
        multiline: {
            boxSizing: "border-box",
            //padding: "1rem 0"
        }
    },
    MuiFormLabel: {
        root: {
            color: theme.palette.text.primary
        }
    },
    /* ======================== */
    // TOOLTIPS
    /* ======================== */
    MuiTooltip: {
        popper: {
            pointerEvents: "none"
        },
        tooltip: {
            pointerEvents: "none",
            backgroundColor: theme.palette.background.dark
        },
        tooltipPlacementTop: {
            pointerEvents: "none",
            '&:before': {
                pointerEvents: "none",
                content: "' '",
                top: '100%',
                left: '50%',
                width: '0',
                height: '0',
                display: 'block',
                position: 'absolute',
                transform: 'translate(-50%,0%)',
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderTop: '6px solid #2B2B2B',
            },
        },
        tooltipPlacementBottom: {
            pointerEvents: "none",
            '&:before': {
                pointerEvents: "none",
                content: "' '",
                top: '-25%',
                left: '50%',
                width: '0',
                height: '0',
                display: 'block',
                position: 'absolute',
                transform: 'translate(-50%,0%)',
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderBottom: '6px solid #2B2B2B',
            },
        },
        tooltipPlacementLeft: {
            pointerEvents: "none",
            '&:before': {
                pointerEvents: "none",
                content: "' '",
                top: '50%',
                left: '100%',
                width: '0',
                height: '0',
                display: 'block',
                position: 'absolute',
                transform: 'translate(0%,-50%)',
                borderLeft: '6px solid #2B2B2B',
                borderBottom: '6px solid transparent',
                borderTop: '6px solid transparent',
            },
        },
        tooltipPlacementRight: {
            pointerEvents: "none",
            '&:before': {
                pointerEvents: "none",
                content: "' '",
                top: '50%',
                left: '0%',
                width: '0',
                height: '0',
                display: 'block',
                position: 'absolute',
                transform: 'translate(0%,-50%)',
                borderRight: '6px solid #2B2B2B',
                borderBottom: '6px solid transparent',
                borderTop: '6px solid transparent',
            },
        }
    }
}
/* ========================================== */
// Components
// ----------
// Common reuseable styles for component classes
/* ========================================== */
theme.components = {
    /* ======================== */
    // GENERAL
    /* ======================== */
    // === Icon ===
    icon: {
        fontSize: "1.5rem"
    },
    // === Border ===
    border: {
        border: "0.05rem solid #6D6E70",
        borderRadius: "0.25rem"
    },
    // === Input ===
    input: {
        margin: "0 0.5rem",
        width: "calc(100% - 1rem)",
        height: "2.5rem",
        color: "#FFFFFF"
    },
    /* ======================== */
    // SIDEBAR
    /* ======================== */
    sidebar: {
        position: "relative",
        //maxWidth: "40rem",
        minWidth: "30rem",
        width: "40%",
        height: "100%"
    },
    /* ======================== */
    // SIDEBAR > HEADER
    /* ======================== */
    header: {
        width: "100%",
        height: "8rem"
    },
    /* ======================== */
    // ACTIVITIES
    /* ======================== */
    /* ======================== */
    // BUTTONS
    /* ======================== */
    // === Main Button ===
    button: {
        width: "2.5rem",
        height: "2.5rem",
        minWidth: "2.5rem",
        minHeight: "2.5rem",
        padding: 0
    },
    // === Main Button w/ formatting ===
    primaryButton: {
        width: "2.5rem",
        height: "2.5rem",
        minWidth: "2.5rem",
        minHeight: "2.5rem",
        padding: 0,
        backgroundColor: "#404041",
        color: "#A6A8AB",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#2B2B2B",
        }
    },
    // === Active Button ===
    activeButton: {
        color: "#97D13B", //teal["A400"],
        backgroundColor: "#2B2B2B",
    },
    /* ======================== */
    // LOADING
    /* ======================== */
    // === Component Loading Sequence ===
    componentLoading: {
        loadingSequence: {
            position: "absolute",
            width: "50%",
            height: "50%",
            textAlign: "center",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
        },
        loadingImage: {
            width: "5rem",
            height: "5rem"
        }
    },
    /* ======================== */
    // DRAGGABLE COMPONENTS
    /* ======================== */
    draggable: {
        // === Dragging Animation ===
        dragging: {
            border: "0.1rem solid #97D13B", //+ teal["A400"],
            transition: "border 0.2s ease",
            boxSizing: "content-box",
        }
    },
    /* ======================== */
    // SELECT HEADERS
    /* ======================== */
    select: {
        // === Checkbox ===
        checkbox: {
            width: "2.5rem",
            height: "2.5rem",
        },
        // Checkbox - Icon
        checkIcon: {
            fontSize: "1rem",
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "0.5rem"
        },
        // === Header ===
        header: {
            width: "100%",
            height: "2.5rem",
            borderBottom: "0.05rem solid #6D6E70",
            boxSizing: "content-box",
        },
        // === Select ===
        // SelectMenu - Small Form Control
        formControlSmall: {
            width: "calc(100% - 5rem)",
            height: "2.5rem",
            display: "inline-block",
            verticalAlign: "middle",
        },
        formControlSmallCloseable: {
            width: "calc(100% - 6.5rem)",
            height: "2.5rem",
            display: "inline-block",
            verticalAlign: "middle",
        },
        formControlIndicator: {
            width: "calc(100% - 8.5rem)",
            height: "2.5rem",
            display: "inline-block",
            verticalAlign: "middle",
        },
        // SelectMenu - StandardForm Control
        formControl: {
            width: "calc(100% - 2.5rem)",
            height: "2.5rem",
            display: "inline-block",
            verticalAlign: "middle",
        },
        formControlCloseable: {
            width: "calc(100% - 4.5rem)",
            height: "2.5rem",
            display: "inline-block",
            verticalAlign: "middle",
        },
        // SelectMenu - Disabled Class Override
        disabled: {
            cursor: "inherit"
        },
        // SelectMenu - Select Component Root
        select: {
            width: "100%",
            // Hide Native Icon
            '& svg': {
                display: "none"
            },
        },
        // SelectMenu - Select Component Field
        selectField: {
            padding: 0,
            marginLeft: "0.5rem",
            fontSize: "1.1rem",
            color: "#FFFFFF",
        },
        // SelectMenu - Input Adjustments
        input: {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%"
        },
        // SelectMenu - Button (Overriding Native Select IconButton)
        selectButton: {
            display: "inline-block",
            verticalAlign: "middle",
            width: "2rem",
            height: "2rem",
            // Select Icon
            '& svg': {
                fontSize: "2rem"
            }
        },
        headerButton: {
            display: "inline-block",
            verticalAlign: "middle",
            width: "2rem",
            height: "2rem",
            // Select Icon
            '& svg': {
                fontSize: "1.5rem",
            },
            '&.inactive': {
                '& svg': {
                    fill: "#A6A8AB"
                }
            }
        },
        // SelectMenu - Menu
        selectMenu: {},
    }
}
export default theme;