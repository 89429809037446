/* ================================================================ */
// STORY PAGE COMPONENT | Story.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
// Actions
import { fetchPanels } from "../../../actions/DataActions";
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
// Local Components
import Panels from "../../../containers/Panels";
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        display:"flex"
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center"
    },
    loading: {
        width: "50%",
    },
});
/* ========================================== */
/**
 * Story Page
 * ----------
 * Outputs panels according to specified story
 * 
 * @param {object} classes style classes
 * @param {string} page page name (same as story name)
 * @param {object} screen screen width and height
 */
/* ========================================== */
class Story extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        page: PropTypes.string.isRequired
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // Fetch panels for story (from page name) on first load
        console.debug("Story/Page:", this.props.page);
        if(this.props.page) {
            this.props.fetchPanels(this.props.page);
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.page !== this.props.page) {
            this.props.fetchPanels(this.props.page);
        }
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        if(this.props.loading) {
            return (<div className={classes.loadingContainer}><CircularProgress size={200} /></div>);
        } else {
            // Render story panels
            return (
                <div className={classes.root}>
                    <Panels story={this.props.page} panels={this.props.panels} />
                </div>
            )
        }
    }
}
/* ========================================== */
// Redux
/* ========================================== */
const mapStateToProps = store =>{
    return { 
        loading: store.DataReducer.loading, 
        panels: store.DataReducer.panels,
        //screen: store.GlobeReducer.screen,
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({fetchPanels, /*updateMapView, updateMapStyle,*/ dispatch}), dispatch)
}
/* ========================================== */
// Export
/* ========================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Story));