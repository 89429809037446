/* ================================================================ */
// HTML PAGE COMPONENT | HTMLPage.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
// Container
import Activities from '../../containers/Activities';
import Iframe from './Pages/Iframe';
import Story from './Pages/Story';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        overflow:"hidden",
        position: "relative",
        width: "100%",
        height: "calc(100% - 8rem)"
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        height: "calc(100% - 8rem)"
    },
    loading: {
        width: "50%",
    }
});
/* ========================================== */
/**
 * HTMLPage
 * --------
 * Page component
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 * @param {string} type variant page type (activity, feed, default)
 */
/* ========================================== */
/* ======================== */
// CONSTANTS
/* ======================== */
const PAGE_TYPES = {
    ACTIVITY: 'activity',
    FEED: 'feed',
    STORY: 'story',
    DEFAULT: 'default',
    IFRAME: 'iframe'
}
class HTMLPage extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        type: PropTypes.oneOf(Object.values(PAGE_TYPES))
    }
    static defaultProps = {
        type: PAGE_TYPES.DEFAULT
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    render() {
        const classes = this.props.classes;
        // Render Page by Page Type
        switch(this.props.type) {
            case PAGE_TYPES.ACTIVITY:
                return (<Activities {...this.props} />);
            case PAGE_TYPES.IFRAME:
                return (<Iframe {...this.props} />);
            case PAGE_TYPES.STORY:
                return (<Story {...this.props} />);
            default:
                return (null)
        }
    }
}
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(HTMLPage);