/* ================================================================ */
// Reducers | index.js
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import { combineReducers } from 'redux';
import DataReducer from './DataReducer';
import GlobeReducer from './GlobeReducer';
import MapReducer from './MapReducer';
/* ========================================== */
// Reducer Combiner
/* ========================================== */
const reducers = combineReducers({
    DataReducer,
    GlobeReducer,
    MapReducer
})
/* ========================================== */
// Exports
/* ========================================== */
export default reducers;