/* ================================================================ */
// SIDEBAR CONTAINER | Sidebar.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Route, Switch} from 'react-router-dom';
// Material UI
import withStyles from '@material-ui/core/styles/withStyles';
// Actions
import { fetchMenu, fetchPages } from "../actions/GlobeActions";
// Local Components
import Header from '../components/Header';
import Branding from '../components/Branding';
import Menu from '../components/Menu';
import HTMLPage from '../components/HTMLPage';
//import Dashboard from '../containers/Dashboard';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    root: {
        ...theme.components.sidebar
    },
    header: {
        display: "flex",
        width: "100%",
        height: "5rem",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    logoContainer: {
        display: "flex",
        width: "100%",
        margin: "1rem"
    },
    logo: {
        marginRight: "1rem"
    },
    tabs: {
        fontSize: "0.9rem"
    },
    graphContainer: {
        height: "15rem",
        backgroundColor: theme.palette.background.dark
    },
    filterContainer: {
        height: "15rem",
        backgroundColor: theme.palette.background.default
    }
});
/* ========================================== */
/**
 * Sidebar
 * -------
 * Sidebar layout container
 * 
 * @param {object} classes style classes
 * @param {object} screen screen width and height
 * @param {string} page current page name
 */
/* ========================================== */
class Sidebar extends Component {
    /* ======================== */
    // PROPS
    /* ======================== */
    static propTypes = {
        classes: PropTypes.object.isRequired,
        screen: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }),
        page: PropTypes.string
    }
    static defaultProps = {
        page: 'timeline'
    }
    /* ======================== */
    // CONSTRUCTOR
    /* ======================== */
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if(this.props.pages.length === 0) {
            this.props.fetchPages()
        }
        if(this.props.menu.length === 0) {
            this.props.fetchMenu()
        }
    }
    /* ======================== */
    // RENDER
    /* ======================== */
    // Render pages by route
    renderPages() {
        // Get page types
        let pageTypes = {};
        this.props.pages.map(page=>{
            if(pageTypes[page.type]) {
                pageTypes[page.type].push(page);
            } else {
                pageTypes[page.type] = [page];
            }
        });
        // Populate routes by page type
        const routes = Object.keys(pageTypes).map(type=>{
            const path = `/(${pageTypes[type].map(page=>{return (`${page.path}`)}).join('|')})`;
            return (
                <Route
                    key={`route_${type}`}
                    exact 
                    path={path} 
                    render={routeProps=>{
                        return (
                            <HTMLPage
                                {...routeProps}
                                type={type} 
                                page={this.props.page}
                            />
                        )
                    }}
                />
            )
        });
        return (
            <Switch>
                {routes}
            </Switch>
        )
    }
    render() {
        const classes = this.props.classes;
        return (
            <section className={classes.root}>
                <Header branding={<Branding/>}/>
                <Menu page={this.props.page} menu={this.props.menu} />
                {this.renderPages()}
            </section>
        )
    }
}
/* ========================================== */
// Redux
/* ========================================== */
const mapStateToProps = store =>{
    return { 
        loading: store.GlobeReducer.loading, 
        pages: store.GlobeReducer.pages,
        menu: store.GlobeReducer.menu,
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({fetchMenu, fetchPages, dispatch}), dispatch)
}
/* ========================================== */
// Export
/* ========================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar));