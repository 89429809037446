/* ================================================================ */
// POPUP COMPONENT | Popup.jsx
/* ================================================================ */
/* ========================================== */
// Imports
/* ========================================== */
import React from 'react';
import PropTypes from 'prop-types';
// MaterialUI Components
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// MaterialUI Style
import { isNullOrUndefined } from 'util';
import withStyles from '@material-ui/core/styles/withStyles';
/* ========================================== */
// Style Settings
/* ========================================== */
const styles = theme => ({
    // === POPUP CONTAINER ROOT STYLES ===
    root: {
        position: 'absolute',
        //border: `0.05em solid ${theme.palette.primary.light}`,
        backgroundColor: theme.palette.background.dark,
        top: 0,
        left: 0,
        padding: '0.5em 1em',
        width: '10em',
        height: 'auto'//'25em',
    },
    imageContainer: {
        margin: '1rem auto',
        width: '80%',
        height: '33%'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    text: {
        fontSize: '1em',
        color: theme.palette.primary.contrastText
    }
});
/* ========================================== */
/** 
 * Popup Component
 * ---------------
 * Map popup visualisation component
 * 
/* ========================================== */
function Popup(props) {
    //const classes = useStyles();
    const {classes, coords, content} = props;
    const {title, image, contact, summary} = content;
    /* ======================== */
    // RENDER
    /* ======================== */
    // Main render
    if(props.coords)
        return (
            <Paper style={{left: `calc(${coords.x}px - 5em)`, top: `calc(${coords.y}px + 1em)`}} className={classes.root}>
                <Typography className={classes.text} align="center" gutterBottom gutterTop onClick={props.onClick} variant="h2">{title}</Typography>
                {!isNullOrUndefined(image) && image.length > 0 && 
                    <div className={classes.imageContainer}>
                        <img alt={`${title}_image`} className={classes.image} src={image} />
                    </div>
                }
                {!isNullOrUndefined(contact) && contact.length > 0 && 
                    <Typography className={classes.text} gutterTop gutterBottom align="left" variant="h3">{contact}</Typography>
                }
                <Typography className={classes.text} align="left" variant="body1">{summary}</Typography>
            </Paper>
        );
    else return null;
}
/* ======================== */
// PROPS
/* ======================== */
Popup.propTypes = {
    title: PropTypes.string,
    coords: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    }),
    content: PropTypes.shape({
        title: PropTypes.string,
        image: PropTypes.string,
        summary: PropTypes.string
    })
};
Popup.defaultProps = {
    content: []
};
/* ========================================== */
// Export
/* ========================================== */
export default withStyles(styles)(Popup);
